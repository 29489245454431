import React, { useContext, useEffect, useState } from 'react'
import Layout from '../../ReusableComp/Layout'
import ListingContent from './ListingContent'
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchWithFormDataToken } from '../../../apiGeneralFunction';
import { SearchContext } from '../../ReusableComp/SearchContext';

// const useQuery = () => {
//   return new URLSearchParams(useLocation().search);
// };

const ListingPage = () => {

  // const query = useQuery();
  //   const searchQuery = query.get('search')

  // // const { searchQuery, setSearchResults } = useContext(SearchContext);
  // console.log("Listingpage searchQuery", searchQuery);

  const navigate = useNavigate();

  const [data, setData] = useState(null);
  const [categoryData,setCategoryData] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    fetchData();
    fetchCategoryData();
  }, []);

  const fetchData = async () => {

    const abortController = new AbortController();

    try {
      const formdata = new FormData();
      formdata.append("device_type", "web");

      const result = await fetchWithFormDataToken(`api/v1/general_settings-app-data`, formdata, abortController, null);
      if (result?.status === true) {
        setData(result?.data)
      }
      else {

      }
    } catch (error) {

    }
    finally {

      abortController.abort();
    }
  };

  const fetchCategoryData = async () => {

    const abortController = new AbortController();

    try {
      const formdata = new FormData();
      formdata.append("device_type", "web");

      const result = await fetchWithFormDataToken(`api/v1/category_list`, formdata, abortController, null);
      if (result?.status === true) {
        setCategoryData(result?.data)
      }
      else {

      }
    } catch (error) {

    }
    finally {

      abortController.abort();
    }
  };


  // console.log("ListingPage",data);

    return (
        <Layout>
            <ListingContent 
            filterCategories={categoryData} 
            data={data?.filter_options || ''}
            />
        </Layout>
    )
}

export default ListingPage