import React, { useEffect, useRef, useState } from 'react';
import * as Images from '../../../../assets';
import { fetchData } from '../../../../apiGeneralFunction';
import ImageGallery from 'react-image-gallery';
import { useDrag } from 'react-use-gesture';
import { Gallery, GalleryImage } from "react-gesture-gallery";
import 'react-image-gallery/styles/css/image-gallery.css';



const HomeBanner = ({ data }) => {

    const [index, setIndex] = useState(0);

    // console.log("HomeBanner", data);


    const ImagesData = [
        {
            src: 'https://cdn.builder.io/api/v1/image/assets/TEMP/d0b2e3cee26d6a80d33ec962c1e4f140d89145fb8542c9e5c33440b4a1f39091?apiKey=8e5983036f284a13866aae97819cdb73&',
            thumbnail: 'https://example.com/thumbnail1.jpg',
            description: 'Image 1 Description'
        },
        {
            src: 'https://cdn.builder.io/api/v1/image/assets/TEMP/d0b2e3cee26d6a80d33ec962c1e4f140d89145fb8542c9e5c33440b4a1f39091?apiKey=8e5983036f284a13866aae97819cdb73&',
            thumbnail: 'https://example.com/thumbnail2.jpg',
            description: 'Image 2 Description',
        },
        {
            src: 'https://cdn.builder.io/api/v1/image/assets/TEMP/d0b2e3cee26d6a80d33ec962c1e4f140d89145fb8542c9e5c33440b4a1f39091?apiKey=8e5983036f284a13866aae97819cdb73&',
            thumbnail: 'https://example.com/thumbnail2.jpg',
            description: 'Image 2 Description',
        },
    ];

    // useEffect(() => {
    //     const intervalId = setInterval(() => {
    //         setIndex((prevIndex) => (prevIndex + 1) % ImagesData?.length);
    //     }, 3000);

    //     return () => clearInterval(intervalId);
    // }, [ImagesData]);
    const renderCustomControls = () => {
        return (
          <div className="home_banner_custom-dots">
            {data && data.length > 0
              && data.map((_, i) => (
                  <button
                    key={i}
                    className={`home_banner_custom-dot ${index === i ? 'active' : ''}`}
                    onClick={() => setIndex(i)}
                  />
                ))
            }
          </div>
        );
      };


    return (
        <>
            <section className="relative  flex justify-center items-center  bg-white"
            >

                <div
                    className={`flex flex-col max-w-full w-full`}
                >
                    <div
//  renderCustomControls={renderCustomControls}
                    >
                        <Gallery
                            index={index}
                            onRequestChange={i => {
                                setIndex(i);
                            }}
                            // thumbnails={false}
                            showIndicators={true}
                        >

                            {data && data?.length > 0 ? (
                                data.map((img, index) => (
                                    <GalleryImage objectFit="contain" key={index} src={img?.bannerImage} 
                                    
                                    />
                                ))
                            ) : (
                               []
                            )
                            }



                        </Gallery>

                    </div>
                </div>

            </section>


        </>
    )
}

export default HomeBanner 