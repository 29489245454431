import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { ThreeBreadcrumb, TwoBreadcrumb } from '../../../ReusableComp/Breadcrumb';
import { Range } from 'react-range';
import MultiRangeSlider from "multi-range-slider-react";
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchWithFormDataToken } from '../../../../apiGeneralFunction';
import { BgButton, OutlineButton, width } from '../../../ReusableComp/Buttons';
import ImgComp from '../../../ReusableComp/ImgComp';
import StarRating from '../../../ReusableComp/StarRating';
import { SearchContext, useSearch } from '../../../ReusableComp/SearchContext';
import { isEmptyObject } from '../../../ReusableComp/Validations';
// import { SearchContext } from "../../../ReusableComp/Layout/NavbarComp/Navbar";
const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

const ListingContent = ({ filterCategories, data }) => {

    const minPriceInputRef = useRef(null);
    const maxPriceInputRef = useRef(null);



    const query = useQuery().get('search');
    const { setQuery } = useSearch();

    // console.log("Listingcontent query", query);
    const navigate = useNavigate();
    const location = useLocation();
    const [searchNavbarValue, setSearchNavbarValue] = useState("");


    const { categoryId, seller_id } = location?.state || {};
    const { selectedCategoryValue } = location.state || {};

    // console.log("ListingContent seller categoryId id", selectedCategoryValue);

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const handleDrawerToggle = () => {
        setIsDrawerOpen(!isDrawerOpen);
    };

    const [selectedValue, setSelectedValue] = useState(null);
    const [selectedBrandValue, setSelectedBrandValue] = useState([]);
    const [selectedGenderValue, setSelectedGenderValue] = useState([]);
    const [selectedColorValue, setSelectedColorValue] = useState([]);
    const [selectedPurityValue, setSelectedPurityValue] = useState([]);
    const [selectedMetalValue, setSelectedMetalValue] = useState([]);
    const [selectedOccasionValue, setSelectedOccasionValue] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [selectedId, setSelectedId] = useState(null);
    const [updateData, setUpdateData] = useState(null);
    const [sellerId, setSellerId] = useState(seller_id);
    const [category_id, setCategory_id] = useState(selectedCategoryValue);
    const [totalPages, setTotalPages] = useState();
    const [isOpen, setIsOpen] = useState(true);
    const [isOpenGender, setIsOpenGender] = useState(false);
    const [isOpenOccasion, setIsOpenOccasion] = useState(false);
    const [isOpenPurity, setIsOpenPurity] = useState(false);
    const [isOpenMetal, setIsOpenMetal] = useState(false);
    const [isOpenColor, setIsOpenColor] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);


    const [minValue, setMinValue] = useState('');
    const [maxValue, setMaxValue] = useState('');
    const [shouldFocus, setShouldFocus] = useState(false);
    const [debouncedMinValue, setDebouncedMinValue] = useState(minValue);
    const [debouncedMaxValue, setDebouncedMaxValue] = useState(maxValue);
    const [maxValueError, setMaxValueError] = useState('');



    const handleToggle = () => {
        setIsOpen(!isOpen);

    };

    const handleToggleGender = () => {
        setIsOpenGender(!isOpenGender);

    };

    const handleToggleOccasion = () => {
        setIsOpenOccasion(!isOpenOccasion);

    };

    const handleTogglePurity = () => {
        setIsOpenPurity(!isOpenPurity);

    };

    const handleToggleMetal = () => {
        setIsOpenMetal(!isOpenMetal);

    };

    const handleToggleColor = () => {
        setIsOpenColor(!isOpenColor);

    };

    // console.log("sellerId", sellerId);

    // const minVal = data[0]?.min_price || '';
    // const numMin = parseInt(minVal);
    // const maxVal = data[0]?.max_price || '';
    // const numMax = parseInt(maxVal);


    // console.log("range slider", minValue, maxVal);

    useEffect(() => {

        if (!minValue || !debouncedMaxValue) {
            setMaxValueError(""); // Clear the error message if either value is empty
        } else if(debouncedMaxValue >= minValue) {
            setMaxValueError("");
            handleSubmit({
                id: selectedValue,
                colorIds: selectedColorValue,
                purityIds: selectedPurityValue,
                metalIds: selectedMetalValue,
                genderIds: selectedGenderValue,
                occasionIds: selectedOccasionValue,
                page: 1,
                minPrice: minValue || '',
                maxPrice: debouncedMaxValue || '',
                search: '',
            });
        } else {
            setMaxValueError("Max price must be greater than Min price");
        }

    }, [debouncedMaxValue,minValue]);

    // const handleMinValue = (e) => {
    //     localStorage.removeItem('selectedParams');
    //     setMinValue(e.target.value);
    //     setDebouncedMinValue(e.target.value);
    // };

    const handleMaxValue = (e) => {
        localStorage.removeItem('selectedParams');
        setMaxValue(e.target.value);
        setDebouncedMaxValue(e.target.value);

    };


    const handleMinValue = (e) => {

        localStorage.removeItem('selectedParams');

        const newMinValue = e.target.value;

        setMinValue(newMinValue);
        e.target.focus();
        const colorIds = selectedColorValue;
        const categoryId = selectedValue;
        const purityIds = selectedPurityValue;
        const genderIds = selectedGenderValue;
        const metalIds = selectedMetalValue;
        const occasionIds = selectedOccasionValue;
        const maxPrice = '';
        const search = query || '';
        setQuery('');


        handleSubmit({
            id: categoryId, colorIds: colorIds, purityIds: purityIds, metalIds: metalIds, genderIds: genderIds, occasionIds: occasionIds, page: 1,
            minPrice: newMinValue, maxPrice: maxValue, search: search,
        });
    }

    // const handleMaxValue = (e) => {

    //     localStorage.removeItem('selectedParams');

    //     const newMaxValue = e.target.value;
    //     setMaxValue(newMaxValue)
    //     const colorIds = selectedColorValue;
    //     const categoryId = selectedValue;
    //     const purityIds = selectedPurityValue;
    //     const genderIds = selectedGenderValue;
    //     const metalIds = selectedMetalValue;
    //     const occasionIds = selectedOccasionValue;

    //     // const search = query || '';
    //     const search = '';
    //     setQuery('');

    //     if (maxValue >= minValue) {
    //         const minPrice = minValue || '';
    //         handleSubmit({
    //             id: categoryId, colorIds: colorIds, purityIds: purityIds, metalIds: metalIds, genderIds: genderIds, occasionIds: occasionIds, page: 1,
    //             minPrice: minPrice, maxPrice: newMaxValue, search: search,
    //         });
    //     } else {

    //         setMaxValue(newMaxValue)
    //     }


    // }


    // console.log("handleClick selectedValue", selectedValue);
    const handleBrandClick = (e) => {

        localStorage.removeItem('selectedParams');

        setIsDrawerOpen(false)
        const { value, checked } = e.target;
        if (checked) {
            setSelectedBrandValue((prevBrands) => [...prevBrands, value]);
        } else {
            setSelectedBrandValue((prevBrands) => prevBrands.filter((brand) => brand !== value));
        }
    };

    const handleGenderClick = (e, id) => {

        localStorage.removeItem('selectedParams');

        setIsDrawerOpen(false)
        const { value, checked } = e.target;
        // console.log("handleGenderClick", value, id, checked);
        // if (checked) {
        //     setSelectedGenderValue((prevBrands) => [...prevBrands, id]);
        // } else {
        //     setSelectedGenderValue((prevBrands) => prevBrands.filter((brand) => brand !== id));
        // }
        // handleSubmit(selectedGenderValue.join(','));
        // setSelectedGenderValue((prevGenders) => {
        //     const updatedGenders = checked ? [...prevGenders, id] : prevGenders.filter((gender) => gender !== id);
        //     handleSubmit({ id: selectedValue, genderIds: updatedGenders });
        //     return updatedGenders;
        // });
        // setSelectedGenderValue((prevGenders) => {
        //     const updatedGenders = checked ? [...prevGenders, id] : prevGenders.filter((gender) => gender !== id);
        //     const genderIdsString = updatedGenders.join(',');
        //     handleSubmit({ id: selectedValue, genderIds: genderIdsString });
        //     return updatedGenders;
        // });
        const currentGenderIndex = selectedGenderValue.indexOf(id);
        const newSelectedGenderValue = [...selectedGenderValue];

        if (currentGenderIndex === -1) {
            newSelectedGenderValue.push(id);
        } else {
            newSelectedGenderValue.splice(currentGenderIndex, 1);
        }
        setSelectedGenderValue(newSelectedGenderValue);
        // console.log("selectedPurityValue newSelectedGenderValue", newSelectedGenderValue);
        const colorIds = selectedColorValue;
        const categoryId = selectedValue;
        const metalIds = selectedMetalValue;
        const occasionIds = selectedOccasionValue;
        const purityIds = selectedPurityValue;
        const maxPrice = maxValue || '';
        const minPrice = minValue || '';
        const sortId = selectedId;
        const search = query || '';
        setQuery('');
        handleSubmit({
            id: categoryId, colorIds: colorIds, purityIds: purityIds, genderIds: newSelectedGenderValue, metalIds: metalIds,
            occasionIds: occasionIds, maxPrice: maxPrice,
            minPrice: minPrice, sortId: sortId, search: search, page: 1
        })
    };
    // console.log("handleGenderClick selectedGenderValue", selectedGenderValue.join(','));

    const handleColorClick = (e, id) => {

        localStorage.removeItem('selectedParams');

        setIsDrawerOpen(false)
        const currentIndex = selectedColorValue.indexOf(id);
        const newSelectedValue = [...selectedColorValue];

        if (currentIndex === -1) {
            newSelectedValue.push(id);
        } else {
            newSelectedValue.splice(currentIndex, 1);
        }
        setSelectedColorValue(newSelectedValue)
        // const { value, checked } = e.target;
        // console.log("handleColorClick", id);
        // if (checked) {
        //     setSelectedColorValue((prevBrands) => [...prevBrands, id]);
        // } else {
        //     setSelectedColorValue((prevBrands) => prevBrands.filter((brand) => brand !== id));
        // }
        // console.log("selectedColorValue newSelectedValue", newSelectedValue);
        const purityIds = selectedPurityValue;
        const categoryId = selectedValue;
        const metalIds = selectedMetalValue;
        const occasionIds = selectedOccasionValue;
        const genderIds = selectedGenderValue;
        const maxPrice = maxValue || '';
        const minPrice = minValue || '';
        const sortId = selectedId;
        const search = query || '';
        setQuery('');
        handleSubmit({
            id: categoryId, colorIds: newSelectedValue, purityIds: purityIds, genderIds: genderIds, metalIds: metalIds, occasionIds: occasionIds,
            maxPrice: maxPrice,
            minPrice: minPrice, sortId: sortId, search: search, page: 1
        })
        // handleSubmit({colorIds:newSelectedValue,page: 1})
    };

    // console.log("handleGenderClick selectedColorValue", selectedColorValue);

    const handlePurityClick = (e, id) => {

        localStorage.removeItem('selectedParams');

        setIsDrawerOpen(false)
        // const { value, checked } = e.target;
        // if (checked) {
        //     setSelectedPurityValue((prevBrands) => [...prevBrands, id]);
        // } else {
        //     setSelectedPurityValue((prevBrands) => prevBrands.filter((brand) => brand !== id));
        // }
        const currentPurityIndex = selectedPurityValue.indexOf(id);
        const newSelectedPurityValue = [...selectedPurityValue];

        if (currentPurityIndex === -1) {
            newSelectedPurityValue.push(id);
        } else {
            newSelectedPurityValue.splice(currentPurityIndex, 1);
        }
        setSelectedPurityValue(newSelectedPurityValue);
        // console.log("selectedPurityValue newSelectedPurityValue", newSelectedPurityValue);
        const colorIds = selectedColorValue;
        const categoryId = selectedValue;
        const metalIds = selectedMetalValue;
        const occasionIds = selectedOccasionValue;
        const genderIds = selectedGenderValue;
        const maxPrice = maxValue || '';
        const minPrice = minValue || '';
        const sortId = selectedId;
        const search = query || '';
        setQuery('');
        handleSubmit({
            id: categoryId, colorIds: colorIds, purityIds: newSelectedPurityValue, genderIds: genderIds, metalIds: metalIds,
            occasionIds: occasionIds, maxPrice: maxPrice,
            minPrice: minPrice, sortId: sortId, search: search, page: 1
        })
    };

    const handleMetalClick = (e, id) => {

        localStorage.removeItem('selectedParams');

        setIsDrawerOpen(false)
        // const { value, checked } = e.target;
        // if (checked) {
        //     setSelectedMetalValue((prevBrands) => [...prevBrands, id]);
        // } else {
        //     setSelectedMetalValue((prevBrands) => prevBrands.filter((brand) => brand !== id));
        // }
        const currentMetalIndex = selectedMetalValue.indexOf(id);
        const newSelectedMetalValue = [...selectedMetalValue];

        if (currentMetalIndex === -1) {
            newSelectedMetalValue.push(id);
        } else {
            newSelectedMetalValue.splice(currentMetalIndex, 1);
        }
        setSelectedMetalValue(newSelectedMetalValue);
        // console.log("selectedPurityValue newSelectedMetalValue", newSelectedMetalValue);
        const colorIds = selectedColorValue;
        const categoryId = selectedValue;
        const purityIds = selectedPurityValue;
        const occasionIds = selectedOccasionValue;
        const genderIds = selectedGenderValue;
        const maxPrice = maxValue || '';
        const minPrice = minValue || '';
        const sortId = selectedId;
        const search = query || '';
        setQuery('');
        handleSubmit({
            id: categoryId, colorIds: colorIds, purityIds: purityIds, metalIds: newSelectedMetalValue, genderIds: genderIds,
            occasionIds: occasionIds, maxPrice: maxPrice,
            minPrice: minPrice, sortId: sortId, search: search, page: 1
        })

    };
    const handleOccasionClick = (e, id) => {

        localStorage.removeItem('selectedParams');

        setIsDrawerOpen(false)
        // const { value, checked } = e.target;
        // if (checked) {
        //     setSelectedOccasionValue((prevBrands) => [...prevBrands, id]);
        // } else {
        //     setSelectedOccasionValue((prevBrands) => prevBrands.filter((brand) => brand !== id));
        // }
        const currentOccasionIndex = selectedOccasionValue.indexOf(id);
        const newSelectedOccasionValue = [...selectedOccasionValue];

        if (currentOccasionIndex === -1) {
            newSelectedOccasionValue.push(id);
        } else {
            newSelectedOccasionValue.splice(currentOccasionIndex, 1);
        }
        setSelectedOccasionValue(newSelectedOccasionValue);
        // console.log("selectedPurityValue newSelectedOccasionValue", newSelectedOccasionValue);
        const colorIds = selectedColorValue;
        const categoryId = selectedValue;
        const purityIds = selectedPurityValue;
        const metalIds = selectedMetalValue;
        const genderIds = selectedGenderValue;
        const maxPrice = maxValue || '';
        const minPrice = minValue || '';
        const sortId = selectedId;
        const search = query || '';
        setQuery('');
        handleSubmit({
            id: categoryId, colorIds: colorIds, purityIds: purityIds, metalIds: metalIds, genderIds: genderIds,
            occasionIds: newSelectedOccasionValue, maxPrice: maxPrice,
            minPrice: minPrice, sortId: sortId, search: search, page: 1
        })
    };

    const handleSelectChange = (event) => {

        localStorage.removeItem('selectedParams');

        setIsDrawerOpen(false)
        const newsortIdValue = event.target.value;
        setSelectedId(newsortIdValue);
        const categoryId = selectedValue;
        const colorIds = selectedColorValue;
        const purityIds = selectedPurityValue;
        const metalIds = selectedMetalValue;
        const occasionIds = selectedOccasionValue;
        const genderIds = selectedGenderValue;
        // handleSubmit({ id, page: 1 });
        const maxPrice = maxValue || '';
        const minPrice = minValue || '';
        const search = query || '';
        setQuery('');

        handleSubmit({
            id: categoryId, colorIds: colorIds, purityIds: purityIds, genderIds: genderIds, metalIds: metalIds, occasionIds: occasionIds, maxPrice: maxPrice,
            minPrice: minPrice, sortId: newsortIdValue, search: search, page: 1
        })
    };

    // useEffect(() => {
    //     const savedParams = localStorage.getItem('selectedParams');
    //     if (savedParams) {
    //         const parsedParams = JSON.parse(savedParams);

    //         // Restore the filters from localStorage
    //         setSelectedValue(parsedParams.id);
    //         setSelectedColorValue(parsedParams.colorIds || []);
    //         setSelectedPurityValue(parsedParams.purityIds || []);
    //         setSelectedGenderValue(parsedParams.genderIds || []);
    //         setSelectedMetalValue(parsedParams.metalIds || []);
    //         setSelectedOccasionValue(parsedParams.occasionIds || []);
    //         setMaxValue(parsedParams.maxPrice || '');
    //         setMinValue(parsedParams.minPrice || '');
    //         setSelectedId(parsedParams.sortId || '');
    //         setQuery(parsedParams.search || '');

    //         // Fetch the filtered results based on the restored parameters
    //         handleSubmit(parsedParams);
    //     }
    // }, []);
    useEffect(() => {
        const savedParams = localStorage.getItem('selectedParams') || '';

        if (savedParams) {
            const {
                id,
                colorIds,
                purityIds,
                genderIds,
                metalIds,
                occasionIds,
                maxPrice,
                minPrice,
                sortId,
                search,
            } = JSON.parse(savedParams);

            setSelectedValue(id);
            setSelectedColorValue(colorIds || []);
            setSelectedPurityValue(purityIds || []);
            setSelectedGenderValue(genderIds || []);
            setSelectedMetalValue(metalIds || []);
            setSelectedOccasionValue(occasionIds || []);
            setMaxValue(maxPrice || '');
            setMinValue(minPrice || '');
            setSelectedId(sortId || '');
            setQuery(search || '');

            handleSubmit({
                id,
                colorIds,
                purityIds,
                genderIds,
                metalIds,
                occasionIds,
                maxPrice,
                minPrice,
                sortId,
                search,
                page: 1,
            });
        }
    }, []);


    const ProductCards = ({ product_image, name, price, selling_price, discount, total_review, total_rating, rating_avg, id, discount_type }) => {

        const navigate = useNavigate();

        const handleCardClick = () => {
            const params = {
                id: selectedValue,
                colorIds: selectedColorValue,
                purityIds: selectedPurityValue,
                genderIds: selectedGenderValue,
                metalIds: selectedMetalValue,
                occasionIds: selectedOccasionValue,
                maxPrice: maxValue || '',
                minPrice: minValue || '',
                sortId: selectedId,
                search: query || '',

                page: 1
            };

            localStorage.setItem('selectedParams', JSON.stringify(params));
            navigate('/product_detail', { state: { id } });

        };

        return (

            <article
                // onClick={() => navigate('/product_detail', { state: { id } })}
                onClick={handleCardClick}
                className="cursor-pointer relative flex justify-between flex-col  mx-auto w-full bg-white rounded-lg border border-gray-300 border-solid hover:shadow-2xl hover:border-0 max-md:pl-5 max-md:mt-6"
            // className="max-h-full h-full relative flex justify-between flex-col grow mx-auto w-full bg-white rounded-lg border border-gray-300 border-solid hover:shadow-2xl hover:border-0 max-md:pl-5 max-md:mt-6"
            // className="justify-between flex flex-col grow px-3.5 py-4 mx-auto w-full bg-white rounded-md border border-gray-200 border-solid max-md:mt-4 cursor-pointer"
            >{discount > 0 &&
                <div className="text-sm font-medium bg-red-700 text-white absolute px-3 py-1 right-0 rounded-bl-lg">
                    {/* {discount} off */}
                    {discount_type === 'flat' ? (
                        <span>
                            {data?.discount < 0 ? '' : 'Flat ₹'} &nbsp;
                            {Math.abs(discount)} off
                        </span>
                    )
                        :

                        <span>

                            {Math.abs(discount)}
                            {discount < 0 ? '' : '%'} off
                        </span>
                    }
                </div>
                }
                <div className=" p-5 flex justify-between flex-col w-full h-full">
                    <ImgComp
                        src={product_image}
                        // className="self-center aspect-[1.10] w-[200px] "
                        className="object-contain xl:w-full  xl:h-48 2xl:w-full 2xl:h-48 max-sm:w-40 max-sm:h-40 md:w-48 md:h-48 lg:w-full lg:h-40 max-sm:mx-auto"
                    />
                    <div className="mt-3 text-base font-semibold text-ellipsis text-neutral-900">{name}</div>
                    <div className="flex gap-1.5 mt-1.5 items-center justify-between">
                        <div className="text-sm text-neutral-900">

                            {/* <span className="line-through text-neutral-400">{price?.toLocaleString('en-US')} </span>
                        <span className="line-through text-neutral-400"> ₹ </span>  */}
                            {price !== selling_price &&
                                <>
                                    <span className="line-through text-neutral-400">
                                        {price?.toLocaleString('en-US')}
                                    </span>{" "} &nbsp;
                                    <span className="line-through text-neutral-400">₹</span>{" "} &nbsp; &nbsp;
                                </>
                            }


                            <span className=" text-neutral-900">  {selling_price?.toLocaleString('en-US')} ₹</span>
                        </div>
                        {/* <div className="text-xs font-medium text-green-600">{discount} off</div> */}
                    </div>
                    <div className="flex gap-1.5 justify-between mt-1.5">
                        <div className="flex gap-1 my-auto">
                            {/* {reviewsImages?.map((src, index) => (
                        <img key={index} loading="lazy" src={src} alt="" className="shrink-0 w-3 aspect-square" />
                    ))} */}
                            {/* <StarRating value={total_review} /> */}
                            <StarRating value={rating_avg} />
                        </div>
                        {(total_rating !== 0 || total_rating === "0" || total_rating === "") && (

                            <div className="text-xs leading-5 text-center text-neutral-400">
                                ({total_rating}) reviews
                            </div>
                        )}
                        {/* <div className="text-xs leading-5 text-center text-neutral-400">({total_rating}) reviews</div> */}
                    </div>
                </div>
            </article>
        )
    };

    const handleClick = (event, id) => {

        localStorage.removeItem('selectedParams');
        // console.log("id value category", id);
        setIsDrawerOpen(false)
        // console.log("handleClick", id, event.target.value);
        setSelectedValue(id);

        //setSelectedValue(id);
        // handleSubmit();
        const colorIds = selectedColorValue;
        const purityIds = selectedPurityValue;
        const metalIds = selectedMetalValue;
        const occasionIds = selectedOccasionValue;
        const genderIds = selectedGenderValue;
        // handleSubmit({ id, page: 1 });
        const maxPrice = maxValue || '';
        const minPrice = minValue || '';
        const sortId = selectedId;
        // const search = query || '';
        const search = '';
        setQuery('');
        setCategory_id('');

        handleSubmit({
            id: id, colorIds: colorIds, purityIds: purityIds, genderIds: genderIds, metalIds: metalIds, occasionIds: occasionIds, maxPrice: maxPrice,
            minPrice: minPrice, sortId: sortId, search: search, page: 1
        })
    };

    const FilterCategory = ({ categoryName, id, category_id, selectedValue, handleClick }) => {

        return (

            <div
                className={`flex flex-1 gap-2 text-sm leading-5 whitespace-nowrap items-center`}
                // className={`flex flex-1 gap-2 text-sm leading-5 whitespace-nowrap ${(id === category_id || selectedValue === id) ? 'text-red-600' : 'text-neutral-400'
                //     }`}
                onClick={(event) => handleClick(event, id)}

            >
                {/* <input
                    type="radio"
                    id={id}
                    name="category"
                    value={id}
                    // id={id === category_id || selectedValue.includes(id) }
                    // name={id === category_id || selectedValue.includes(id) }
                    // value={id === category_id || selectedValue.includes(id) }
                    // className={` ${id === category_id || selectedValue.includes(id) ? 'text-black' : 'text-neutral-400'}`}
                    // checked={id === category_id || selectedValue.includes(id)}
                    className={` ${(id === category_id || selectedValue === id) ? 'text-black' : 'text-neutral-400'}`}
                    // checked={id === category_id || selectedValue.includes(id)}
                    // checked={id === category_id || selectedValue.includes(id) }
                    checked={id === category_id || selectedValue === id}
                    // checked={id === category_id}
                    onChange={(event) => handleClick(event, id)}
                /> */}
                {(id === category_id || selectedValue === id) ?
                    <i className='fa fa-dot-circle-o mybzb_text_clr text-sm'

                    ></i>
                    :
                    <i className='fa fa-circle-o mybzb_text_clr'></i>
                }
                <div

                    className={`flex flex-1 gap-2 text-sm leading-5 whitespace-nowrap ${(id === category_id || selectedValue === id) ? 'text-black' : 'text-neutral-400'}`}
                >
                    {categoryName}
                </div>
            </div>
        )
    };

    const FilterSection = ({ title, categories }) => {

        return (
            <section className="flex flex-col">
                <div
                    className='flex justify-between items-center'
                    onClick={handleToggle} >
                    <h2 className="text-lg font-bold text-neutral-900">{title}</h2>
                    {isOpen ?
                        <i className="fa fa-angle-up"></i>
                        :
                        <i className="fa fa-angle-down"></i>
                    }
                </div>
                {isOpen &&
                    <div className="mt-4  grid grid-cols-1 w-full  gap-5">
                        {categories && categories?.length > 0 && categories?.map((category, index) => (
                            <FilterCategory key={index} {...category}
                                category_id={category_id}
                                selectedValue={selectedValue}
                                handleClick={handleClick}
                            />
                        ))}
                    </div>
                }
            </section>
        )
    };

    const FilterCheckbox = ({ label }) => (

        <div className={`flex flex-1 gap-2 text-sm leading-5 whitespace-nowrap `}
        >
            <input type="checkbox" id={label} name={label} value={label}
                className={` ${selectedBrandValue.includes(label) ? 'mybzb_text_clr' : 'text-neutral-400'}`}
                onChange={handleBrandClick}
                checked={selectedBrandValue.includes(label)}
            />
            <label htmlFor={label}
                className={`flex flex-1 gap-2 text-sm leading-5 whitespace-nowrap ${selectedBrandValue === label ? 'text-black' : 'text-neutral-400'}`}
            >
                {label}
            </label>
        </div>
    );

    const CheckboxBrandFilter = ({ title, filters }) => (
        <section className="flex flex-col">
            <h2 className="text-lg font-medium text-neutral-900">{title}</h2>
            <div className="mt-4  grid grid-cols-2 w-full  gap-5">
                {filters.map((filters, index) => (
                    <FilterCheckbox key={index} {...filters} />
                ))}
            </div>
        </section>
    );


    const dropdown = [
        {
            id: 1,
            title: 'Popular'
        },
        {
            id: 2,
            title: 'Low Price'
        },
        {
            id: 3,
            title: ' High Price'
        },
        {
            id: 4,
            title: 'Asc'
        },
        {
            id: 5,
            title: 'Desc'
        }
    ]

    const GenderFilter = ({ title, filters }) => {

        // console.log("GenderFilter", filters);
        const FilterCheckbox = ({ label, title, id }) => (

            <div className={`flex flex-1 gap-2 text-sm leading-5 whitespace-nowrap items-center`}
            >
                <input type="checkbox" id={id} name={title} value={title}
                    className={` ${selectedGenderValue.includes(id) ? 'mybzb_text_clr' : 'text-neutral-400'} rounded-md`}
                    onChange={(e) => handleGenderClick(e, id)}
                    checked={selectedGenderValue.includes(id)}
                />
                <label htmlFor={title}
                    className={`flex flex-1  gap-2 text-sm leading-5 whitespace-nowrap ${selectedGenderValue === title ? 'text-black' : 'text-neutral-400'}`}
                >
                    {title}
                </label>
            </div>
        );
        return (
            <section className="flex flex-col mt-4">
                <div
                    className='flex justify-between items-center'
                    onClick={handleToggleGender} >
                    <h2 className="text-lg font-bold text-neutral-900">{title}</h2>
                    {isOpenGender ?
                        <i className="fa fa-angle-up"></i>
                        :
                        <i className="fa fa-angle-down"></i>
                    }
                </div>
                {isOpenGender &&
                    <div className="mt-4  grid grid-cols-1 w-full  gap-5">
                        {filters && filters?.length > 0 && filters?.map((filters, index) => (
                            <FilterCheckbox key={index} {...filters} />
                        ))}
                    </div>
                }
            </section>
        )
    }

    const ColorFilter = ({ title, filters }) => {


        const FilterCheckbox = ({ label, title, id }) => (

            <div className={`flex flex-1 gap-2 text-sm leading-5 whitespace-nowrap items-center`}
            >
                <input type="checkbox" id={id} name={title} value={title}
                    className={` ${selectedColorValue.includes(id) ? 'mybzb_text_clr' : 'text-neutral-400'} rounded-md`}
                    onChange={(e) => handleColorClick(e, id)}
                    checked={selectedColorValue.includes(id)}
                />
                <label htmlFor={label}
                    className={`flex flex-1 gap-2 text-sm leading-5 whitespace-nowrap ${selectedColorValue === title ? 'text-black' : 'text-neutral-400'}`}
                >
                    {title}
                </label>
            </div>
        );
        return (
            <section className="flex flex-col mt-4">
                <div
                    className='flex justify-between items-center'
                    onClick={handleToggleColor} >
                    <h2 className="text-lg font-bold text-neutral-900">{title}</h2>
                    {isOpenColor ?
                        <i className="fa fa-angle-up"></i>
                        :
                        <i className="fa fa-angle-down"></i>
                    }
                </div>
                {isOpenColor &&
                    <div className="mt-4 grid grid-cols-1 w-full  gap-5">
                        {filters && filters?.length > 0 && filters?.map((filters, index) => (
                            <FilterCheckbox key={index} {...filters} />
                        ))}
                    </div>
                }
            </section>
        )
    }

    const PurityFilter = ({ title, filters }) => {


        const FilterCheckbox = ({ label, title, id }) => (

            <div className={`flex flex-1 gap-2 text-sm leading-5 whitespace-nowrap items-center`}
            >
                <input type="checkbox" id={id} name={title} value={title}
                    className={` ${selectedPurityValue.includes(id) ? 'mybzb_text_clr' : 'text-neutral-400'} rounded-md`}
                    onChange={(e) => handlePurityClick(e, id)}
                    checked={selectedPurityValue.includes(id)}
                />
                <label htmlFor={title}
                    className={`flex flex-1 gap-2 text-sm leading-5 whitespace-nowrap ${selectedPurityValue === title ? 'text-black' : 'text-neutral-400'}`}
                >
                    {title}
                </label>
            </div>
        );
        return (
            <section className="flex flex-col mt-4">
                <div
                    className='flex justify-between items-center'
                    onClick={handleTogglePurity} >
                    <h2 className="text-lg font-bold text-neutral-900">{title}</h2>
                    {isOpenPurity ?
                        <i className="fa fa-angle-up"></i>
                        :
                        <i className="fa fa-angle-down"></i>
                    }
                </div>
                {isOpenPurity &&
                    <div className="mt-4  grid grid-cols-1 w-full  gap-5">
                        {filters && filters?.length > 0 && filters?.map((filters, index) => (
                            <FilterCheckbox key={index} {...filters} />
                        ))}
                    </div>
                }
            </section>
        )
    }

    const MetalFilter = ({ title, filters }) => {


        const FilterCheckbox = ({ label, title, id }) => (

            <div className={`flex flex-1 gap-2 text-sm leading-5 whitespace-nowrap items-center`}
            >
                <input type="checkbox" id={id} name={title} value={title}
                    className={` ${selectedMetalValue.includes(id) ? 'mybzb_text_clr' : 'text-neutral-400'} rounded-md`}
                    onChange={(e) => handleMetalClick(e, id)}
                    checked={selectedMetalValue.includes(id)}
                />
                <label htmlFor={title}
                    className={`flex flex-1 gap-2 text-sm leading-5 whitespace-nowrap ${selectedMetalValue === title ? 'text-black' : 'text-neutral-400'}`}
                >
                    {title}
                </label>
            </div>
        );
        return (
            <section className="flex flex-col mt-4">
                <div
                    className='flex justify-between items-center'
                    onClick={handleToggleMetal} >
                    <h2 className="text-lg font-bold text-neutral-900">{title}</h2>
                    {isOpenMetal ?
                        <i className="fa fa-angle-up"></i>
                        :
                        <i className="fa fa-angle-down"></i>
                    }
                </div>
                {isOpenMetal &&
                    <div className="mt-4  grid grid-cols-1 w-full  gap-5">
                        {filters && filters?.length > 0 && filters?.map((filters, index) => (
                            <FilterCheckbox key={index} {...filters} />
                        ))}
                    </div>
                }
            </section>
        )
    }

    const OccasionFilter = ({ title, filters }) => {


        const FilterCheckbox = ({ label, title, id }) => (

            <div className={`flex flex-1 gap-2 text-sm leading-5 whitespace-nowrap items-center`}
            >
                <input type="checkbox" id={id} name={title} value={title}
                    className={` ${selectedOccasionValue.includes(id) ? 'mybzb_text_clr' : 'text-neutral-400'} rounded-md`}
                    onChange={(e) => handleOccasionClick(e, id)}
                    checked={selectedOccasionValue.includes(id)}
                />
                <label htmlFor={title}
                    className={`flex flex-1 gap-2 text-sm leading-5 whitespace-nowrap ${selectedOccasionValue === title ? 'text-black' : 'text-neutral-400'}`}
                >
                    {title}
                </label>
            </div>
        );
        return (
            <section className="flex flex-col mt-4">
                <div
                    className='flex justify-between items-center'
                    onClick={handleToggleOccasion} >
                    <h2 className="text-lg font-bold text-neutral-900">{title}</h2>
                    {isOpenOccasion ?
                        <i className="fa fa-angle-up"></i>
                        :
                        <i className="fa fa-angle-down"></i>
                    }
                </div>
                {isOpenOccasion &&
                    <div className="mt-4  grid grid-cols-1 w-full  gap-5">
                        {filters && filters?.length > 0 && filters?.map((filters, index) => (
                            <FilterCheckbox key={index} {...filters} />
                        ))}
                    </div>
                }
            </section>
        )
    }

    // console.log("searchValue", searchValue, selectedId);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        // if(query || ''){
        //     handleSubmit({});
        // }

        // const colorIds = selectedColorValue;
        // const categoryId = selectedValue;
        // const purityIds = selectedPurityValue;
        // const genderIds = selectedGenderValue;
        // const metalIds = selectedMetalValue;
        // const occasionIds = selectedOccasionValue;
        // const maxPrice = maxValue || '';
        // const minPrice = minValue || '';
        // const query = query || '';
        // handleSubmit({
        //     id: categoryId, colorIds: colorIds, purityIds: purityIds, metalIds: metalIds, genderIds: genderIds, occasionIds: occasionIds, 
        //     minPrice: minPrice, maxPrice: maxValue,query:query,page: 1,
        // });
        if (query) {
            setSearchNavbarValue(query);
        }
        else {
            handleSubmit({});
        }
        handleSubmit({
            search: searchNavbarValue,
        });

        // console.log("searchNavbarValue", searchNavbarValue, query);

    }, [searchNavbarValue, query])

    useEffect(() => {
        if (!query) {
            // Call handleSubmit with an empty search query
            handleSubmit({ search: '' });
        }
    }, [query]);



    const handleSubmit = async ({ id = "", colorIds = "", purityIds = "", genderIds = "", metalIds = "", occasionIds = "", minPrice = '', maxPrice = '',
        sortId = "", search = "", page = 1, }) => {

        // console.log("handleSubmit selectedValue", id);
        // console.log("handleSubmit colorIds", colorIds);
        // console.log("handleSubmit purityIds", purityIds);
        // console.log("handleSubmit metalIds", metalIds);
        // console.log("handleSubmit occasionIds", occasionIds);
        // console.log("handleSubmit price", minPrice, maxPrice);
        // console.log("handleSubmit search", search);

        const tokenValue = localStorage?.getItem('login api response token');

        const storedToken = JSON?.parse(tokenValue);
        // if (!storedToken) {
        //     navigate('/');

        // }

        const user_id = localStorage?.getItem('user_data');
        const userId = JSON.parse(user_id);

        const savedLocalParams = localStorage.getItem('selectedParams');
        const savedParsedParams = JSON.parse(savedLocalParams);
        // console.log("savedParams", JSON.parse(savedLocalParams))

        const storedSortId = savedParsedParams?.sortId || sortId || '';
        const storedSearch = savedParsedParams?.search || search || query || '';
        const storedCategoryId = savedParsedParams?.id || id || category_id || '';
        const storedMinPrice = savedParsedParams?.minPrice || minPrice || '';
        const storedMaxPrice = savedParsedParams?.maxPrice || maxPrice || '';
        const storedGenderIds = savedParsedParams?.genderIds || genderIds || '';
        const storedPurityIds = savedParsedParams?.purityIds || purityIds || '';
        const storedOccasionIds = savedParsedParams?.occasionIds || occasionIds || '';
        const storedMetalIds = savedParsedParams?.metalIds || metalIds || '';
        const storedColorIds = savedParsedParams?.colorIds || colorIds || '';


        const abortController = new AbortController();

        try {
            const formdata = new FormData();
            // formdata.append("device_type", "web");
            // formdata.append("user_id", userId?.user_id);
            // formdata.append("seller_id", sellerId || '');
            // formdata.append("promotional_id", selectedId || '');
            // formdata.append("search", searchValue || '');
            // formdata.append("category_id", id || selectedValue || category_id || '');
            // formdata.append("price_range_min", minValue || '');
            // formdata.append("price_range_max", maxValue || '');
            // formdata.append("gender", selectedGenderValue[0] || '');
            // formdata.append("purity", selectedPurityValue[0] || '');
            // formdata.append("occasion", selectedOccasionValue[0] || '');
            // formdata.append("metal", selectedMetalValue[0] || '');
            // formdata.append("color", selectedColorValue[0] || '');
            // formdata.append("page", page);



            // formdata.append("device_type", "web");
            // formdata.append("user_id", userId?.user_id);
            // formdata.append("seller_id", sellerId || '');
            // formdata.append("promotional_id", '');
            // formdata.append("sort_by", sortId || '');
            // formdata.append("search", search || '');
            // formdata.append("category_id", id || category_id || '');
            // formdata.append("price_range_min", minPrice || '');
            // formdata.append("price_range_max", maxPrice || '');
            // formdata.append("gender", genderIds || '');
            // formdata.append("purity", purityIds || '');
            // formdata.append("occasion", occasionIds || '');
            // formdata.append("metal", metalIds || '');
            // formdata.append("color", colorIds || '');
            // formdata.append("page", page);

            formdata.append("device_type", "web");
            formdata.append("user_id", userId?.user_id);
            formdata.append("seller_id", sellerId || '');
            formdata.append("promotional_id", '');
            formdata.append("sort_by", storedSortId);
            formdata.append("search", storedSearch);
            formdata.append("category_id", storedCategoryId);
            formdata.append("price_range_min", storedMinPrice);
            formdata.append("price_range_max", storedMaxPrice);
            formdata.append("gender", storedGenderIds);
            formdata.append("purity", storedPurityIds);
            formdata.append("occasion", storedOccasionIds);
            formdata.append("metal", storedMetalIds);
            formdata.append("color", storedColorIds);
            formdata.append("page", page);


            const result = await fetchWithFormDataToken('api/v1/product_list', formdata, abortController, null);
            // console.log("handleSubmit", result?.data);
            if (result?.status === true) {
                if (isEmptyObject(result?.data) || result?.data === null) {
                    setUpdateData(null)
                    setTotalPages(null)
                } else {
                    setUpdateData(result?.data?.productData)
                    setTotalPages(result?.data?.pagination?.total_pages)
                }

            }
            else {

                if (isEmptyObject(result?.data) || result?.data === null) {
                    setUpdateData(null)
                    setTotalPages(null)
                }
            }
        } catch (error) {

        }
        finally {

            abortController.abort();
        }
    };

    const handlePageChange = (newPage) => {
        // handleSubmit(newPage);
        const colorIds = selectedColorValue;
        const categoryId = selectedValue;
        const purityIds = selectedPurityValue;
        const genderIds = selectedGenderValue;
        const metalIds = selectedMetalValue;
        const occasionIds = selectedOccasionValue;
        const maxPrice = maxValue || '';
        const minPrice = minValue || '';
        const search = query || '';
        const sortId = selectedId || '';
        handleSubmit({
            id: categoryId, colorIds: colorIds, purityIds: purityIds, metalIds: metalIds, genderIds: genderIds, occasionIds: occasionIds,
            search: search, maxPrice: maxPrice, minPrice: minPrice, sortId: sortId,
            page: newPage
        });
    }


    const handleResetSubmit = async (e) => {
        e?.preventDefault();
        setQuery('');
        // Reset filter values to empty strings
        setSelectedId('');
        setMaxValue('');
        setMinValue('');
        setSearchNavbarValue('');
        setSearchValue('');
        setSelectedValue('');
        setSelectedGenderValue([]);
        setSelectedPurityValue([]);
        setSelectedOccasionValue([]);
        setSelectedMetalValue([]);
        setSelectedColorValue([]);
        setSellerId('');
        setCategory_id('');
        setCurrentPage(1)
        setMaxValueError('')
        handleSubmit({ page: 1 });


        navigate({
            pathname: location.pathname,
            search: '', // Clear search query
        }, { replace: true });


        // Rest of the code remains the same
        const tokenValue = localStorage?.getItem('login api response token');
        const storedToken = JSON?.parse(tokenValue);

        const user_id = localStorage?.getItem('user_data');
        const userId = JSON.parse(user_id);

        const abortController = new AbortController();

        try {
            const formdata = new FormData();
            formdata.append("device_type", "web");
            formdata.append("user_id", userId?.user_id);
            formdata.append("seller_id", '');
            formdata.append("promotional_id", '');
            formdata.append("search", '');
            formdata.append("category_id", '');
            formdata.append("price_range_min", '');
            formdata.append("price_range_max", '');
            formdata.append("gender", '');
            formdata.append("purity", '');
            formdata.append("occasion", '');
            formdata.append("metal", '');
            formdata.append("color", '');

            const result = await fetchWithFormDataToken('api/v1/product_list', formdata, abortController, null);
            // console.log("handleSubmit", result?.data);
            if (result?.status === true) {
                setUpdateData(result?.data?.productData)
            } else {
                setUpdateData("")
            }
        } catch (error) {

        } finally {
            abortController.abort();
        }
    };

    const Drawer = ({ isOpen, handleDrawerToggle, onMinChange, onMaxChange }) => {


        const [testValue, setTestValue] = useState();
        const [minDrawerValue, setMinDrawerValue] = useState();
        const [maxDrawerValue, setMaxDrawerValue] = useState();

        const handleTest = (e) => {
            setTestValue(e.target.value)
        }

        const handleMaxDrawerValue = (e) => {
            setMaxDrawerValue(e.target.value);
        };


        const handleMinDrawerValue = (e) => {


            const newMinValue = e.target.value;
            setMinDrawerValue(newMinValue);
            const colorIds = selectedColorValue;
            const categoryId = selectedValue;
            const purityIds = selectedPurityValue;
            const genderIds = selectedGenderValue;
            const metalIds = selectedMetalValue;
            const occasionIds = selectedOccasionValue;
            const maxPrice = '';
            const search = query || '';
            setQuery('');


            //  handleSubmit({
            //      id: categoryId, colorIds: colorIds, purityIds: purityIds, metalIds: metalIds, genderIds: genderIds, occasionIds: occasionIds, page: 1,
            //      minPrice: newMinValue, maxPrice: maxDrawerValue, search: search,
            //  });
        }

        const handleDrawerSubmit = () => {
            const colorIds = selectedColorValue;
            const categoryId = selectedValue;
            const purityIds = selectedPurityValue;
            const genderIds = selectedGenderValue;
            const metalIds = selectedMetalValue;
            const occasionIds = selectedOccasionValue;
            const maxPrice = maxDrawerValue;
            const search = query || '';

            // Call handleSubmit with the necessary parameters
            handleDrawerToggle(); // Close the drawer
            handleSubmit({
                id: categoryId,
                colorIds,
                purityIds,
                metalIds,
                genderIds,
                occasionIds,
                page: 1,
                minPrice: minDrawerValue,
                maxPrice,
                search,
            });
        };


        return (
            <div
                className={`fixed top-0 left-0 w-full h-full  overflow-y-auto flex items-center bg-gray-500 bg-opacity-50 z-30 ${isOpen ? 'block' : 'hidden'}`}
                role="dialog"
                aria-modal="true"

            >
                <div className="bg-white rounded-lg p-4 w-full max-w-xs z-50 py-10 h-full overflow-y-auto">
                    <div className='flex items-center justify-between mb-3'>
                        <div className='font-bold text-2xl text-center w-full '>
                            All Filters
                        </div>
                        <div
                            className='cursor-pointer'
                            // onClick={handleDrawerToggle}
                            onClick={handleDrawerSubmit}
                        >
                            <i className='fa fa-close'></i>
                        </div>
                    </div>
                    <hr className='mb-5 text-black' />
                    <div className="flex flex-col max-md:ml-0 max-md:w-full max-md:overflow-y-hidden overflow-x-hidden ">
                        <FilterSection title="Category" categories={filterCategories} />
                        <hr className="shrink-0 mt-6 h-px bg-gray-200 border-gray-200" />
                        <section className="flex flex-col mt-6">
                            <h2 className="text-lg font-bold text-neutral-900">Price Range</h2>
                            {/* <input type="number"
                                name="test"
                                id="test"
                                value={testValue}
                                onChange={handleTest}
                            /> */}
                            <div className="flex gap-3 mt-4 text-sm  grid grid-cols-1 leading-6 text-neutral-400">
                                <div className=''>
                                    {/* <label htmlFor="minValue" className="sr-only">Min price</label> */}
                                    <input
                                        type="number"
                                        id="minDrawerValue"
                                        value={minDrawerValue}
                                        name="minDrawerValue"
                                        onChange={handleMinDrawerValue}
                                        // ref={minPriceInputRef}
                                        // onClick={handleMinPriceClick}
                                        placeholder="Min price"
                                        className="focus:border-gray-200 focus:outline-0 focus:ring-0 flex-1 justify-center items-start px-3 py-3 bg-white text-sm rounded border border-gray-200 max-md:pr-5"
                                    />
                                </div>
                                <div>
                                    {/* <label htmlFor="maxDrawerPrice" className="sr-only">Max price</label> */}
                                    <input type="number"
                                        id="maxDrawerValue"
                                        name='maxDrawerValue'
                                        value={maxDrawerValue}
                                        // onChange={(e) => handleMaxValue(e)}
                                        onChange={handleMaxDrawerValue}
                                        // onClick={handleMaxPriceClick}
                                        // ref={maxPriceInputRef}
                                        placeholder="Max price"
                                        className="focus:border-gray-200 focus:outline-0 focus:ring-0 flex-1 justify-center items-start px-3 py-3  text-sm bg-white rounded border border-gray-200 max-md:pr-5"
                                    />
                                </div>
                            </div>
                        </section>
                        <hr className="shrink-0 mt-6 h-px bg-gray-200 border-gray-200" />
                        <GenderFilter title={data[1]?.title || ''} filters={data[1]?.data || ''} />
                        <hr className="shrink-0 mt-6 h-px bg-gray-200 border-gray-200" />
                        {/* <CheckboxBrandFilter title="Popular Brands" filters={brandTitle} /> */}
                        <ColorFilter title={data[5]?.title || ''} filters={data[5]?.data || ''} />
                        <hr className="shrink-0 mt-6 h-px bg-gray-200 border-gray-200" />
                        <PurityFilter title={data[2]?.title || ''} filters={data[2]?.data || ''} />
                        <hr className="shrink-0 mt-6 h-px bg-gray-200 border-gray-200" />
                        <MetalFilter title={data[4]?.title || ''} filters={data[4]?.data || ''} />
                        <hr className="shrink-0 mt-6 h-px bg-gray-200 border-gray-200" />
                        <OccasionFilter title={data[3]?.title || ''} filters={data[3]?.data || ''} />

                    </div>
                </div>
            </div>
        );
    };

    return (
        <>
            <TwoBreadcrumb
                // name2={`Ring`}
                // href2={`/`}
                name3={`Product Listing Or Search Result`}
                href3={`/`}
            />
            <main className={`flex gap-5 justify-between items-start pt-8 max-md:flex-wrap max-w-${width} mx-auto max-md:max-w-full`}>
                {/* <aside className="flex flex-col items-center">
                    <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/fe36425bbc5495ef3970fe95a3008ed00a9c12c1f75c464f1c52e16fb7ff1c03?apiKey=8e5983036f284a13866aae97819cdb73&" alt="" className="shrink-0 my-auto max-w-full aspect-[0.34] w-[183px] max-md:mt-10" />
                    <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/421957f5a45487bdfdd1e97201aa35aa43f67a46f0e9e68a76f6e63f13292eff?apiKey=8e5983036f284a13866aae97819cdb73&" alt="" className="aspect-[0.59] mt-[1045px] w-[183px] max-md:mt-10" />
                </aside> */}


                <div className="self-start w-[20%] max-lg:hidden">
                    <div className='flex gap-5 justify-start items-center mb-5 mt-3 '>
                        {/* <button
                            className="focus:border-red-800 focus:ring-0 focus:outline-0 self-end text-sm text-end cursor-pointer font-bold leading-5 text-red-700  rounded-lg w-full"
                        >
                            Settings
                        </button> */}
                        <button
                            className="focus:border-red-800 focus:ring-0 focus:outline-0 self-end text-sm text-start cursor-pointer font-bold leading-5 text-red-700  rounded-lg w-full"
                            onClick={(e) => handleResetSubmit(e)}
                        >
                            Reset
                        </button>
                    </div>
                    <div className="flex flex-col max-md:ml-0 max-md:w-full pr-5  h-screen overflow-y-auto overflow-x-hidden ">
                        <FilterSection title="Category" categories={filterCategories} />
                        <hr className="shrink-0 mt-6 h-px bg-gray-200 border-gray-200" />
                        <section className="flex flex-col mt-6 ">
                            <h2 className="text-lg font-bold text-neutral-900">Price Range</h2>

                            <div className="flex gap-3 mt-4 text-sm  flex-col leading-6 text-neutral-400 ">
                                <div className='w-full'>
                                    <label htmlFor="minPrice" className="sr-only">Min price</label>
                                    <input type="text" id="minPrice"
                                        value={minValue}
                                        // onChange={(e) => handleMinValue(e)}
                                        onChange={handleMinValue}
                                        inputMode="numeric"
                                        placeholder="Min price"
                                        className="focus:border-gray-200 focus:outline-0 focus:ring-0 px-3 py-3 bg-white text-sm rounded border border-gray-200 max-md:pr-5 w-full"
                                    />


                                </div>
                                <div className='w-full'>
                                    <label htmlFor="maxPrice" className="sr-only">Max price</label>
                                    <input type="text" id="maxPrice"
                                        value={maxValue}
                                        onChange={handleMaxValue}
                                        inputMode="numeric"
                                        placeholder="Max price"
                                        className="focus:border-gray-200 focus:outline-0 focus:ring-0 px-3 py-3  text-sm bg-white rounded border border-gray-200 max-md:pr-5 w-full"
                                    />
                                    {maxValueError &&
                                        <div className='mybzb_text_clr text-xs leading-normal mt-3'>{maxValueError}</div>
                                    }
                                </div>
                            </div>
                        </section>
                        <hr className="shrink-0 mt-6 h-px bg-gray-200 border-gray-200" />
                        <GenderFilter title={data[1]?.title || ''} filters={data[1]?.data || ''} />
                        <hr className="shrink-0 mt-6 h-px bg-gray-200 border-gray-200" />
                        {/* <CheckboxBrandFilter title="Popular Brands" filters={brandTitle} /> */}
                        <ColorFilter title={data[5]?.title || ''} filters={data[5]?.data || ''} />
                        <hr className="shrink-0 mt-6 h-px bg-gray-200 border-gray-200" />
                        <PurityFilter title={data[2]?.title || ''} filters={data[2]?.data || ''} />
                        <hr className="shrink-0 mt-6 h-px bg-gray-200 border-gray-200" />
                        <MetalFilter title={data[4]?.title || ''} filters={data[4]?.data || ''} />
                        <hr className="shrink-0 mt-6 h-px bg-gray-200 border-gray-200" />
                        <OccasionFilter title={data[3]?.title || ''} filters={data[3]?.data || ''} />

                    </div>
                </div>
                <section className="flex flex-col mx-5 max-md:w-full w-full ">
                    <section className="flex flex-col max-md:mt-6 max-md:max-w-full">
                        <div className="flex gap-5 max-lg:justify-between justify-end items-center w-full text-sm leading-5 max-md:flex-wrap max-md:max-w-full">
                            <div className={` flex w-auto items-center gap-5 lg:hidden ${isDrawerOpen ? '' : ''}`}>
                                <button
                                    className="text-white mybzb_bg_clr px-2 py-1 focus:border-red-800 focus:ring-0 focus:outline-0 self-end text-sm text-end cursor-pointer font-bold leading-5 rounded-md  w-full"
                                    onClick={handleDrawerToggle}
                                >
                                    {isDrawerOpen ? 'Close' : 'All Filters'}
                                    <i className='fa fa-angle-double-right text-base ps-2'></i>
                                </button>
                                <button
                                    className="focus:border-red-800 focus:ring-0 focus:outline-0 text-sm text-start cursor-pointer font-bold text-red-700  rounded-lg"
                                    onClick={(e) => handleResetSubmit(e)}
                                >
                                    Reset
                                </button>
                            </div>
                            <Drawer className={isDrawerOpen ? 'block' : 'hidden'}
                                isOpen={isDrawerOpen}
                                handleDrawerToggle={handleDrawerToggle}
                                onMaxChange={handleMaxValue}
                                onMinChange={handleMinValue}
                                minValue={minValue}
                                maxValue={maxValue}
                            />
                            <div className="flex gap-5 justify-between">
                                <div className="my-auto text-neutral-900 font-bold">Sort by:</div>
                                <div className="flex gap-5 justify-center bg-white rounded-md text-neutral-400 border">
                                    <select value={selectedId} onChange={handleSelectChange}
                                        // className='rounded-lg border border-gray-200 text-sm'
                                        className={`focus:outline-0 focus:ring-0 focus:border-0 border-0 rounded-lg text-sm ${selectedId ? 'mybzbBgClr  ' : ''}`}
                                    >
                                        {dropdown && dropdown?.length > 0 && dropdown?.map((option) => (
                                            <option key={option?.id} value={option?.id} id=""
                                                className={`${selectedId === option.id ? ' text-white' : '  text-neutral-900'} hover:text-white border`}
                                            >
                                                {option?.title}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="flex gap-5 justify-between px-6 py-3 mt-4 w-full text-sm bg-gray-100 rounded-md max-md:flex-wrap max-md:px-5 max-md:max-w-full">
                            <div className="flex gap-4 leading-[143%] text-neutral-900">
                            </div>
                            <div className="leading-5 text-neutral-400">
                                <span className="font-semibold">
                                    {/* {updateData?.length * totalPages} */}
                                    {(isEmptyObject(totalPages) || totalPages === null) ?
                                        0
                                        :
                                        updateData?.length * totalPages
                                    }

                                </span> <span className="text-neutral-400">Results found.</span>
                            </div>
                        </div>
                    </section>
                    <section className="  grid 2xl:grid-cols-4 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 xs:grid-cols-1  grid-cols-4 gap-5  mt-7 max-md:grid-cols-1 overflow-y-auto max-md:max-w-full w-full">
                        {/* {isEmptyObject(data) || data === null ?
                            <>
                                <div>No Data Found</div>
                            </> :
                            updateData && updateData?.length > 0 ? (
                                updateData?.sort((a, b) => b?.id - a?.id)?.map((product, index) => (
                                    <ProductCards key={index} {...product} />
                                ))
                            )
                                : (
                                    <>
                                        <div>No Data Found</div>
                                    </>
                                )

                        } */}

                        {isEmptyObject(data) || data === null ?
                            <>
                                <div>No Data Found</div>
                            </> :
                            updateData && updateData?.length > 0 ? (
                                updateData?.map((product, index) => (
                                    <ProductCards key={index} {...product} />
                                ))
                            )
                                : (
                                    <>
                                        <div>No Data Found</div>
                                    </>
                                )

                        }


                    </section>
                    {(isEmptyObject(totalPages) || totalPages === null) ?
                        <></>
                        :

                        <div className='flex w-full justify-end items-center my-5'>
                            {totalPages > 1 && (
                                Array.from({ length: totalPages }, (_, index) => (
                                    <div key={index + 1}

                                        onClick={() => {
                                            handlePageChange(index + 1);
                                            setCurrentPage(index + 1);
                                        }}
                                        className={`px-3 py-1 border rounded-full text-center ${currentPage === index + 1 ? 'bg-red-800 text-white' : 'hover:bg-red-800 hover:text-white text-black'
                                            } my-5 me-5 cursor-pointer`}
                                    //className='px-3 py-1 border rounded-full text-black text-center hover:bg-red-800 hover:text-white my-5 me-5 cursor-pointer'
                                    >
                                        {index + 1}
                                    </div>
                                ))
                            )}
                        </div>
                    }
                </section>
            </main>

        </>
    )
}

export default ListingContent