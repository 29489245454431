import React, { useEffect, useState } from "react";
import ImgComp from '../../../ReusableComp/ImgComp';
import * as Images from '../../../../assets';
import SimilarProducts from "./SimilarProducts";
import StarRating from "../../../ReusableComp/StarRating";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchWithFormDataToken } from "../../../../apiGeneralFunction";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ThreeBreadcrumb } from "../../../ReusableComp/Breadcrumb";
import { width } from "../../../ReusableComp/Buttons";
import toastPromise from "../../../../services/toastService";


const ProductDetailContent = ({ }) => {

  const [selectedSizeIndex, setSelectedSizeIndex] = useState(null);
  const [selectedWeightIndex, setSelectedWeightIndex] = useState(null);
  // const [selectedSizeIndex, setSelectedSizeIndex] = useState(0);
  // const [selectedWeightIndex, setSelectedWeightIndex] = useState(0);

  const location = useLocation();
  const id = location?.state?.id || localStorage.getItem("ProductDetail id");


  // console.log("ProductDetail id", id);
  localStorage.setItem("ProductDetail id", id)
  const navigate = useNavigate();

  const [data, setData] = useState(null);
  const [feature, setFeature] = useState(null);


  useEffect(() => {
    window.scrollTo(0, 0);
  }, [id]);
  useEffect(() => {
    if (!id) {
      console.error("Product ID not found.");
      navigate('/');
    } else {
      // localStorage.setItem("ProductDetail id", id);
      fetchData();
    }
  }, [id]);

  const fetchData = async () => {

    const tokenValue = localStorage?.getItem('login api response token');
    const storedToken = JSON?.parse(tokenValue);
    // if (!storedToken) {
    //   navigate('/');
    //   // return;
    // }

    const user_id = localStorage?.getItem('user_data');
    const userId = JSON.parse(user_id);

    const ProductId = localStorage.getItem("ProductDetail id") || ''

    const abortController = new AbortController();

    try {

      const formdata = new FormData();
      formdata.append("device_type", "web");
      formdata.append("user_id", userId?.user_id);
      // formdata.append("user_id", "17");
      formdata.append("product_id", ProductId);

      const result = await fetchWithFormDataToken(`api/v1/product_details`, formdata, abortController, null);
      if (result?.status === true) {
        setData(result?.data)
      }
      else {

      }
    } catch (error) {

    }
    finally {

      abortController.abort();
    }
  };

  // console.log("ProductDetail", data);

  // console.log("data images ", data);

  const handleSizeClick = (size) => {
    setSelectedSizeIndex(size)
    setSelectedWeightIndex(size)
  };



  // console.log("selectedSizeIndex",selectedSizeIndex);


  const ImageGallery = () => {

    const images = [
      { src: "https://cdn.builder.io/api/v1/image/assets/TEMP/24f02b4422c298308ef1cffe14b9c5761949f5d18ca7c34e8bd244f1d51450e0?apiKey=8e5983036f284a13866aae97819cdb73&", alt: 'Nature' },
      { src: "https://www.alliedscientificpro.com/web/image/product.template/5920/image_1024?unique=1ffa31b", alt: 'Snow' },
      { src: "https://cdn.builder.io/api/v1/image/assets/TEMP/24f02b4422c298308ef1cffe14b9c5761949f5d18ca7c34e8bd244f1d51450e0?apiKey=8e5983036f284a13866aae97819cdb73&", alt: 'Mountains' },
      { src: "https://cdn.builder.io/api/v1/image/assets/TEMP/24f02b4422c298308ef1cffe14b9c5761949f5d18ca7c34e8bd244f1d51450e0?apiKey=8e5983036f284a13866aae97819cdb73&", alt: 'Lights' },
      { src: "https://cdn.builder.io/api/v1/image/assets/TEMP/24f02b4422c298308ef1cffe14b9c5761949f5d18ca7c34e8bd244f1d51450e0?apiKey=8e5983036f284a13866aae97819cdb73&", alt: 'Mountains' },

    ];


    // const [expandedImgSrc, setExpandedImgSrc] = useState(images[0]?.src);
    // const [expandedImgAlt, setExpandedImgAlt] = useState(images[0]?.alt);
    const [expandedImgSrc, setExpandedImgSrc] = useState(data?.product_images[0]?.image_path);
    const [expandedImgAlt, setExpandedImgAlt] = useState(data?.product_images[0]?.type);
    const [isExpanded, setIsExpanded] = useState(true);
    const [currentIndex, setCurrentIndex] = useState(0);

    // console.log("expandedImgSrc", expandedImgSrc);

    // const handleImageClick = (img) => {
    //   // setExpandedImgSrc(img.src);
    //   // setExpandedImgAlt(img.alt);
    //   setExpandedImgSrc(img?.image_path);
    //   setExpandedImgAlt(img?.type);
    //   setIsExpanded(true);
    // };

    const closeExpandedImage = () => {
      setIsExpanded(false);
    };

    const handlePrevClick = () => {
      setCurrentIndex((prevIndex) => (prevIndex === 0 ? data?.product_images.length - 1 : prevIndex - 1));
    };

    const handleNextClick = () => {
      setCurrentIndex((prevIndex) => (prevIndex === data?.product_images.length - 1 ? 0 : prevIndex + 1));
    };

    const handleImageClick = (index) => {
      setCurrentIndex(index);
    };

    return (
      <div className="">

        <div className="flex flex-col gap-5 max-md:flex-row  justify-between items-center">
          <div className="flex flex-row items-center gap-3">
            {/* {isExpanded && ( */}
            <button
              onClick={handlePrevClick}
              className=" border border-red-800 focus:outline-amber-500 bg-red-800 text-white rounded-full py-2 px-4 "
            // className="border border-red-800 focus:outline-amber-500 absolute top-1/2 left-0 transform -translate-y-1/2 py-2 px-4 bg-red-800 text-white rounded-full"
            >
              &#10094; {/* Left Arrow */}
            </button>
            <div className="  xl:w-full  xl:h-96 2xl:w-full 2xl:h-96  md:w-full md:h-full lg:w-full lg:h-full max-sm:mx-auto mx-auto"
              style={{ position: 'relative', display: 'block' }}

            >
              {/* <span
                className="closebtn"
                style={{ position: 'absolute', top: '10px', right: '15px', color: 'white', fontSize: '35px', cursor: 'pointer' }}
                onClick={closeExpandedImage}
              >
                &times;
              </span> */}

              <img id="expandedImg"
                // src={expandedImgSrc} alt={expandedImgAlt}
                src={data?.product_images[currentIndex]?.image_path}
                alt={data?.product_images[currentIndex]?.type}
                className="rounded-lg object-contain mx-auto xl:w-96  xl:h-96 2xl:w-96 2xl:h-96  md:w-96 md:h-96 lg:w-96 lg:h-96 max-sm:mx-auto mx-auto"
              // style={{ width: '100%' }} 
              />

              {/* <div id="imgtext"
              className="border border-red-500"
              style={{ position: 'absolute', bottom: '15px', left: '15px', color: 'white', fontSize: '20px' }}>
                {expandedImgAlt}
              </div> */}
            </div>
            <button
              onClick={handleNextClick}
              className=" border border-red-800 focus:outline-amber-500 bg-red-800 text-white rounded-full py-2 px-4 "
            // className="border border-red-800 focus:outline-amber-500  absolute top-1/2 right-0 transform -translate-y-1/2 py-2 px-4 bg-red-800 text-white rounded-full"
            >
              &#10095; {/* Right Arrow */}
            </button>
            {/* )} */}
          </div>
          <div className=" row flex flex-row gap-5 xl:w-full 2xl:w-full  md:w-full md:h-full lg:w-full lg:h-full max-sm:mx-auto mx-auto  max-md:flex-col"

          >
            {/* {images.map((img, index) => (
              <div className=" flex w-20 " key={index}

              >
                <img
                  src={img.src}
                  alt={img.alt}

                  onClick={() => handleImageClick(img)}
                  onMouseOver={(e) => (e.currentTarget.style.opacity = 1)}
                  onMouseOut={(e) => (e.currentTarget.style.opacity = 0.8)}
                />
              </div>
            ))} */}
            {data?.product_images.map((img, index) => (
              <div
                // className=" flex w-20 cursor-pointer " 
                className={`flex w-20 cursor-pointer ${index === currentIndex ? 'border border-red-800 rounded-lg' : ''}`}
                key={index}
                onClick={() => handleImageClick(index)}
              >
                <img
                  src={img?.image_path}
                  alt={img?.type}

                  // onClick={() => handleImageClick(img)}
                  // onClick={() => handleImageClick(index)}
                  className="rounded-lg"
                  onMouseOver={(e) => (e.currentTarget.style.opacity = 1)}
                  onMouseOut={(e) => (e.currentTarget.style.opacity = 0.8)}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };


  const InfoItem = ({ label, value }) => {

    const renderGenderContent = (value) => {
      // console.log("renderGenderContent", value);
      switch (value) {
        case "1":
          return 'Male';
        case "2":
          return 'Female';
        case "3":
          return 'Kids';
        case "4":
          return 'Unisex';
        default:
          return null;
      }
    };
    return (
      <div className="flex gap-0">
        <div className="flex-1 justify-center items-start px-3 py-2 font-bold bg-rose-50 text-neutral-900 max-md:pr-5 text-base">
          {label}
        </div>
        <div className="flex-1 justify-center items-start px-3 py-1 border-b border-rose-50 border-solid text-neutral-400 max-md:pr-5 text-base">
          {/* {value} */}
          {label === 'Gender' ? renderGenderContent(value) : value}
        </div>
      </div>
    );
  }

  const JewelleryContent = () => {
    return (
      <div className="px-4 py-6 rounded-lg max-md:max-w-full flex gap-5  ">
        <div className="w-full border border-rose-50 rounded-lg grid lg:grid-cols-2 grid-cols-1">
          {/* {jewelleryInfo.map((item, index) => (
            <InfoItem key={index} label={item.label} value={item.value} />
          ))} */}
          {data?.jewelry_info?.length > 0 && data?.jewelry_info?.map((item, index) => (
            <InfoItem key={index} label={item?.title} value={item.value} />
          ))}
        </div>
        {/* <div className="w-full border border-rose-50 rounded-lg">
          {additionalInfo.map((item, index) => (
            <InfoItem key={index} label={item.label} value={item.value} />
          ))}
        </div> */}
      </div>
    )
  }

  const AboutContent = () => {
    return (
      <>
        {/* {aboutItem.map((item, index) => (
          <div key={index} className="px-4 py-6 rounded-lg max-md:max-w-full text-base bg-white">
            {item.subItem.map((subitem, index) => (
              <div key={index} className="ms-5">
                <ul className="list-disc flex items-center gap-5">
                  <li className="font-bold" >{subitem.label}:</li>
                  <p className=" text-neutral-400 font-medium">{subitem.value}</p>
                </ul>
              </div>
            ))}

            <div className="mt-5">
              <div className="font-bold leading-8">Description</div>
              <div className=" text-neutral-400 font-medium">{item.description}</div>
            </div>
          </div>
        ))} */}
        {data?.about_this_item_html &&
          <div className="px-4 py-6 rounded-lg max-md:max-w-full text-base ">

            <div className=" text-neutral-400 font-medium"
              dangerouslySetInnerHTML={{ __html: data?.about_this_item_html }}
            />
            {/* {data?.about_this_item_html}</div> */}

          </div>
        }

      </>
    )
  }

  const AdditionalContent = () => {
    return (
      <>
        {/* {aboutItem.map((item, index) => (
          <div key={index} className="px-4 py-6 rounded-lg max-md:max-w-full bg-white">
            {item.subItem.map((subitem, index) => (
              <div key={index} className="flex gap-5 text-base my-1">
                <span className="font-bold" >{subitem.label}:</span>
                <p className=" text-neutral-400 font-medium">{subitem.value}</p>
              </div>
            ))}

          </div>
        ))} */}
        {data?.additional_info_html &&
          <div className="px-4 py-6 rounded-lg max-md:max-w-full text-base ">

            <div className=" text-neutral-400 font-medium"
              dangerouslySetInnerHTML={{ __html: data?.additional_info_html }}
            />
            {/* {data?.additional_info_html}</div> */}

          </div>
        }

      </>
    )
  }

  const ImportantInformation = () => {
    return (
      <>
        <div className="my-5">

          <h2 className="text-xl font-semibold leading-8 text-neutral-900 mb-5">
            Important Information
          </h2>
          <div className="mb-3">
            <div className="text-base my-1 font-bold">Safety Information:</div>
            <div className="text-neutral-400 text-base">
              {data?.safety_Information}
            </div>
          </div>
          <div>
            <div className="text-base my-1 font-bold">Legal Disclaimer:</div>
            <div className="text-neutral-400 text-base">
              {data?.legal_Disclaimer}
            </div>
          </div>
        </div>
      </>
    )
  }


  const InformationSection = ({ title, infoItems }) => {

    const [isOpen, setIsOpen] = useState(true);

    const handleToggle = () => {
      setIsOpen(!isOpen);
    };

    const renderContent = () => {
      switch (title) {
        case 'Jewellery Information':
          return <JewelleryContent />;
        case 'About This item':
          return <AboutContent />;
        case 'Additional Information':
          return <AdditionalContent />;
        default:
          return null;
      }
    };

    return (
      <section className="flex flex-col bg-white rounded-xl border border-gray-200 border-solid max-md:max-w-full">
        <header
          onClick={handleToggle}
          className="flex justify-between items-center gap-3 px-4 py-3.5 border-b border-gray-200 max-md:flex-wrap max-md:max-w-full">
          <h2 className="text-xl font-semibold leading-8 text-neutral-900">{title}</h2>

          {isOpen ?
            <i className="fa fa-angle-down"></i>
            :
            <i className="fa fa-angle-up"></i>
          }

        </header>

        {isOpen && (
          <div className="">
            {renderContent()}
          </div>
        )}
      </section>
    );
  }

  const SocialIcons = () => {
    const socialImages = [
      { src: `${Images.social_media_facebook}`, alt: "Social icon 1", url: 'https://www.facebook.com/mybzb' },
      { src: `${Images.social_media_twitter}`, alt: "Social icon 2", url: 'https://twitter.com/my_bzb' },
      { src: `${Images.social_media_pinterest}`, alt: "Social icon 3", url: 'https://in.pinterest.com/my_bzb/' },
      { src: `${Images.social_media_instagram}`, alt: "Social icon 4", url: 'https://www.instagram.com/my_bzb?igsh=MWN6bmE3N3c5MmN1bQ==' },
    ];

    return (
      <div className="flex gap-5 mt-6 max-w-full w-[212px]">
        {socialImages.map((image, index) => (
          <button
            className="focus:outline-0"
            onClick={(e) => {
              e.preventDefault();
              window.open(image?.url, '_blank');
            }}
          >
            <ImgComp
              key={index}
              src={image.src}
              alt={image.alt}
              className="shrink-0 aspect-square w-[38px] cursor-pointer"
            />
          </button>
        ))}
      </div>
    );
  };

  const EmailForm = () => {
    return (
      <form className="flex flex-col p-6 mt-6 w-full font-semibold bg-white rounded-xl border border-gray-200 border-solid leading-[150%] text-neutral-400">
        <div className="self-center text-2xl text-red-700">Weekly Newsletter</div>
        <p className="mt-6 text-xs leading-5 text-center">
          No spam. Just the latest releases and tips, interesting articles, and exclusive interviews
          in your inbox every week.
        </p>
        <label htmlFor="emailInput" className="sr-only">
          Enter your email
        </label>
        <input
          type="email"
          id="emailInput"
          placeholder="Enter your email"
          aria-label="Enter your email"
          className="focus:border-gray-200 focus:outline-0 focus:ring-0  justify-center items-start px-3.5 py-2.5 mt-6 text-sm text-center rounded-md border border-gray-200 border-solid"
        />
        <button
          type="button"
          className="focus:border-0 focus:outline-0 focus:ring-0  justify-center items-center self-center p-2 mt-6 max-w-full text-base text-center text-white whitespace-nowrap bg-red-700 rounded-md w-[249px]"
        >
          Subscribe
        </button>
      </form>
    );
  };

  useEffect(() => {
    if (data?.variation?.length > 0) {
      let defaultWeight = null;
      let defaultSize = null;

      data?.variation?.forEach((variation) => {
        if (variation?.name === "Weight") {
          defaultWeight = variation?.data[0];
        } else if (variation?.name === "Size") {
          defaultSize = variation?.data[0];
        }
      });

      setSelectedWeightIndex(defaultWeight);
      setSelectedSizeIndex(defaultSize);
    }
  }, [data]);

  const handleWeightClick = (weight) => {
    setSelectedWeightIndex(weight)
  };

  // let weight, size;
  // data?.variation?.forEach((variation) => {
  //   if (variation?.name === "Weight") {
  //     weight = variation?.data[0];
  //   } else if (variation?.name === "Size") {
  //     size = variation?.data[0];
  //   }
  // });


  const addWishlistItem = async () => {

    const tokenValue = localStorage?.getItem('login api response token');

    const user_id = localStorage?.getItem('user_data');
    const userId = JSON.parse(user_id);

    const storedToken = JSON?.parse(tokenValue);
    if (!storedToken) {
      navigate('/register');
      return;
    }

    const abortController = new AbortController();

    try {

      const formdata = new FormData();
      formdata.append("user_id", userId?.user_id);
      formdata.append("is_wish", "1");
      formdata.append("product_id", data?.id);

      //  const result = await fetchWithFormDataToken(`api/v1/add_wishlist`, formdata, abortController, null);
      //  if (result?.status === true) {
      //   //  setTimeout(() => {
      //   //    toast.success(result?.message || '', {
      //   //      position: 'top-center',
      //   //      autoClose: 2000,
      //   //    });
      //   //  }, 1000);

      //    fetchData();
      //  }
      //  else {
      //   //  setTimeout(() => {
      //   //    toast.error(result?.message, {
      //   //      position: 'top-center',
      //   //      autoClose: 2000,
      //   //    });
      //   //  }, 1000);
      //  }
      const response = await toastPromise(
        fetchWithFormDataToken('api/v1/add_wishlist', formdata, abortController, null),
        {
          pending: "Adding wishlist...",
          success: (data) => data.message || "Product is added to wishlist",
          error: (data) => data.message || "Failed to added in wishlist",
        }
      );

      if (response.status === true) {
        fetchData();
      }
    } catch (error) {

    }
    finally {

      abortController.abort();
    }
  };

  const removeWishlistItem = async () => {
    const tokenValue = localStorage?.getItem('login api response token');

    const user_id = localStorage?.getItem('user_data');
    const userId = JSON.parse(user_id);

    const storedToken = JSON?.parse(tokenValue);
    if (!storedToken) {
      navigate('/register');
      return;
    }

    const abortController = new AbortController();

    try {

      const formdata = new FormData();
      formdata.append("user_id", userId?.user_id);
      formdata.append("is_wish", "0");
      formdata.append("product_id", data?.id);

      // const result = await fetchWithFormDataToken(`api/v1/remove_wishlist`, formdata, abortController, null);
      // if (result?.status === true) {
      //   // setTimeout(() => {
      //   //   toast.success(result?.message || '', {
      //   //     position: 'top-center',
      //   //     autoClose: 2000,
      //   //   });
      //   // }, 1000);
      //   fetchData();
      // }
      // else {
      //   // setTimeout(() => { 
      //   //   toast.error(result?.message, {
      //   //     position: 'top-center',
      //   //     autoClose: 2000,
      //   //   });
      //   // }, 1000);
      // }

      const response = await toastPromise(
        fetchWithFormDataToken('api/v1/remove_wishlist', formdata, abortController, null),
        {
          pending: "Removing Product...",
          success: (data) => data.message || "Product is removed",
          error: (data) => data.message || "Failed to remove product",
        }
      );

      if (response.status === true) {
        fetchData();
      }
    } catch (error) {

    }
    finally {

      abortController.abort();
    }
  };


  const addCartItem = async () => {

    const tokenValue = localStorage?.getItem('login api response token');

    const user_id = localStorage?.getItem('user_data');
    const userId = JSON.parse(user_id);

    const storedToken = JSON?.parse(tokenValue);
    if (!storedToken) {
      navigate('/register');
      // return;
    }

    const abortController = new AbortController();

    try {

      const formdata = new FormData();
      formdata.append("user_id", userId?.user_id);
      formdata.append("seller_id", data?.seller_id);
      formdata.append("product_id", data?.id);
      formdata.append("quantity", "1");
      formdata.append("size", selectedSizeIndex || 0);
      formdata.append("weight", selectedWeightIndex || '');

      // const result = await fetchWithFormDataToken(`api/v1/product_add_to_cart`, formdata, abortController, null);
      // if (result?.status === true) {
      //   setTimeout(() => {
      //     toast.success(result?.message || '', {
      //       position: 'top-center',
      //       autoClose: 2000,
      //     });
      //     navigate('/shoppingcart')
      //   }, 2000);

      // }
      // else {
      // }
      const response = await toastPromise(
        fetchWithFormDataToken('api/v1/product_add_to_cart', formdata, abortController, null),
        {
          pending: "Product is added to cart...",
          success: (data) => data.message || "Product is added to cart successful",
          error: (data) => data.message || "Failed to add the product in the cart",
        }
      );

      if (response.status === true) {
        navigate('/shoppingcart')
      }
    } catch (error) {

    }
    finally {

      abortController.abort();
    }
  };

  function UserReview({
    // name, date, avatarSrc, ratingSrc, text, images, data,rating
    name = '',
    date = '',
    avatarSrc = '',
    ratingSrc = '',
    text = '',
    images = [],
    rating = 0,
    data = {}
  }) {
    let formattedDate = '';

    if (date) {
      const dateValue = Date.parse(date);
      if (!isNaN(dateValue)) {
        formattedDate = new Intl.DateTimeFormat('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit'
        }).format(new Date(dateValue));
      } else {
        // console.log("Invalid date value");
      }
    } else {
      // console.log("Date is not available");
    }
    return (
      <article className="flex flex-col mt-3">
        <div className="flex gap-4 leading-[150%] max-md:flex-wrap">
          {/* <img loading="lazy" src={avatarSrc} alt={`Avatar of ${name}`} className="shrink-0 w-12 rounded-full aspect-square" /> */}
          <ImgComp
            src={avatarSrc}
            className="shrink-0 w-12 rounded-full aspect-square"
          />
          <div className="flex flex-col flex-1 my-auto max-md:max-w-full">
            <div className="text-sm font-bold text-neutral-900 max-md:max-w-full">{name}</div>
            <div className="flex gap-3 justify-between mt-1 text-sm text-neutral-400 max-md:flex-wrap max-md:max-w-full">
              {/* <img loading="lazy" src={ratingSrc} alt={`Rating by ${name}`} className="shrink-0 self-start w-24 aspect-[5.88]" /> */}
              {/* <div className="flex gap-1 items-center">
                <div>
                  <i className="fa fa-star"></i>
                </div>
                <div>
                  <i className="fa fa-star text-amber-500"></i>
                </div>
              </div> */}
              {/* <StarRating value={data?.product_reviews?.rating}/> */}
              <StarRating value={rating} />
              {formattedDate && <time dateTime={new Date(date).toISOString()}>{formattedDate}</time>}
              {/* <time dateTime={new Date(date).toISOString()}>{date}</time> */}
              {/* {onlyDate || ''} */}
              {/* {onlyDate && <time dateTime={new Date(dateValue).toISOString()}>{onlyDate}</time>} */}
            </div>
          </div>
        </div>
        <p className="mt-4 text-sm leading-5 text-neutral-400 max-md:max-w-full">{text}</p>
        <div className="flex gap-3 self-start mt-4">
          {images?.map((imageSrc, index) => (
            <>
              <ImgComp
                src={imageSrc}
                className="shrink-0 aspect-square w-[72px]"
              />
              {/* <img key={index} loading="lazy" src={imageSrc} alt={`Review image ${index + 1}`} className="shrink-0 aspect-square w-[72px]" /> */}
            </>
          ))}
        </div>
      </article>
    );
  }

  const hasProductReviews = data?.product_reviews && Object.keys(data.product_reviews).length > 0;


  return (
    <>
      <ThreeBreadcrumb
        name2={data?.category_name}
        name3={`Product Details`}
        href2={`/listing`}
        href3={`/`}
        
      />
      {/* <div
        className="flex justify-center items-center bg-white w-full "
      > */}
      <main
        className={`flex gap-5 justify-between items-start pt-8 max-md:flex-wrap max-w-${width} mx-auto max-md:max-w-full`}
      //  className="flex flex-col justify-center self-stretch p-8 bg-white max-md:px-0  w-[1296px] mx-auto max-w-full"
      // className={`flex flex-col items-center self-center px-5 max-w-${width} max-md:px-0 max-md:max-w-full`}
      >

        <section className="flex flex-col px-8 py-10  bg-white rounded-lg w-full max-md:max-w-full max-md:px-5">
          <div className="max-md:max-w-full w-full">
            <div className="flex gap-5 max-md:flex-col max-md:gap-0 ">
              <div className="flex flex-col w-[45%] max-md:ml-0 max-md:w-full ">
                {/* <ProductImgList /> */}
                <ImageGallery />

              </div>
              <div className="flex flex-col ml-5 w-[55%] max-md:ml-0 max-md:w-full">
                <div className="flex flex-col grow max-md:mt-6 max-md:max-w-full">
                  <div className="flex gap-2.5 self-start text-xs leading-5 text-right">
                    <div className="flex gap-1 py-1 pr-3 pl-2 font-medium text-white whitespace-nowrap bg-amber-500 rounded items-center">

                      <i className="fa fa-star"></i>
                      <div>{data?.rating_avg || ''}</div>
                    </div>
                    <div className="my-auto text-neutral-400">{data?.rating_avg || ''}k Reviews</div>
                  </div>


                  <h1 className="mt-2 text-xl leading-7 text-zinc-900 max-md:max-w-full">
                    {data?.product_name || ''}
                  </h1>
                  <div className="flex gap-5 justify-between mt-4 text-sm leading-5 text-zinc-900 max-md:flex-wrap">
                    <div>
                      <span className="text-gray-500">Brand: </span>
                      <span className="font-semibold text-zinc-900">MyBzB</span>
                    </div>
                    {/* <div className="max-md:max-w-full">
                    <span className="text-gray-500">Category: </span>
                    <span className="font-semibold text-zinc-900">Ring</span>
                  </div> */}
                  </div>
                  {/* <div className="flex gap-5 justify-between mt-2 text-sm leading-5 max-md:flex-wrap">
                  <div className="text-zinc-900">
                    <span className="text-gray-500">Sku: </span>
                    <span className="font-semibold text-zinc-900">A264671</span>
                  </div>
                  <div className="text-green-600 max-md:max-w-full">
                    <span className="text-gray-500">Availability: </span>
                    <span className="font-semibold text-green-600"> In Stock </span>
                  </div>
                </div> */}
                  <div className="flex gap-2 items-center self-start mt-6 leading-[150%]">
                    <div className="self-stretch text-2xl font-bold text-red-700"> ₹ {Number(data?.selling_price).toLocaleString('en-IN')}</div>
                    {/* <div className="self-stretch my-auto text-base text-neutral-400 line-through"> ₹ {data?.original_price?.toLocaleString('en-IN')}</div> */}
                    {data?.original_price !== data?.selling_price &&
                      <div className="self-stretch my-auto text-base text-neutral-400 line-through"> ₹ {Number(data?.original_price).toLocaleString('en-IN')}</div>
                    }
                    {data?.discount > 0 && (
                      <div className="justify-center self-stretch p-1 my-auto text-sm font-medium text-green-600 rounded-lg bg-green-600 bg-opacity-10">
                        {/* {data?.discount}% off */}
                        {data?.discount_type === 'flat' ? (
                          <span>
                            {data?.discount < 0 ? '' : 'Flat ₹'}
                            {Math.abs(data?.discount)} off
                          </span>
                        )
                          :

                          <span>

                            {Math.abs(data?.discount)}
                            {data?.discount < 0 ? '' : '%'} off
                          </span>
                        }
                      </div>
                    )}
                  </div>
                  <hr className="shrink-0 mt-6 h-px bg-gray-200 border-border-gray-200 max-md:max-w-full" />
                  <div className="mt-6 text-sm font-bold leading-5 text-neutral-900 max-md:max-w-full">
                    <span className="">Select Size -</span> <span className="">Gross Weight</span>
                  </div>
                  <div className="mt-1 text-xs leading-5 text-neutral-400 max-md:max-w-full">
                    Weight and price of the jewellery item may very subject to the stock available
                  </div>
                  <div className="flex flex-wrap">
                    {data?.variation?.length > 0 ? (

                      data?.variation?.map((item, index) => (

                        <div className="flex gap-5 pr-20 mt-3 leading-[150%] max-md:flex-wrap max-md:pr-5" key={index}>
                          <div className="flex flex-col">
                            <h3 className="text-sm font-semibold text-neutral-900"> {item?.name} in {item?.type} </h3>
                            <div className="flex gap-3 mt-3 text-xs text-red-700 ">
                              {item?.data.map((size, index) => (
                                size !== "" ? (
                                  <div
                                    key={index}
                                    // onClick={() => handleSizeClick(size)}
                                    onClick={() => item?.name === "Weight" ? handleWeightClick(size) : handleSizeClick(size)}
                                    // className={
                                    //   // index === 0
                                    //   size === selectedSizeIndex 
                                    //     ? "justify-center px-3 py-1 bg-rose-50 border border-red-700 border-solid rounded-[360px] cursor-pointer"
                                    //     : "justify-center px-3 py-1 bg-rose-50 rounded-[360px]  cursor-pointer"
                                    // }
                                    className={
                                      (item?.name === "Weight" && size === selectedWeightIndex) ||
                                        (item?.name === "Size" && size === selectedSizeIndex)
                                        ? "justify-center px-3 py-1 bg-rose-50 border border-red-700 border-solid rounded-[360px] cursor-pointer"
                                        : "justify-center px-3 py-1 bg-rose-50 rounded-[360px] cursor-pointer"
                                    }
                                  >
                                    {size}
                                  </div>
                                )
                                  :
                                  <></>
                              ))}
                            </div>
                          </div>

                        </div>

                      ))


                    ) : (
                      <></>
                    )
                    }
                  </div>


                  <section
                    // className="flex gap-4 self-stretch grid grid-cols-3 pt-3 text-base font-bold tracking-normal justify-end text-end uppercase leading-[56px] max-md:flex-wrap my-5"
                    className="flex gap-4 grid grid-cols-2 text-base font-bold tracking-normal justify-start items-end text-end uppercase leading-[56px] my-5 max-sm:grid-cols-1"
                  >
                    <button
                      //  onClick={() => navigate('shoppingcart', { state: { cartId : data } })}
                      onClick={() => addCartItem()}
                      // className={`flex-1 grow justify-center items-center px-8 text-white bg-red-700 rounded max-md:px-5  w-full`}
                      className={` focus:outline-amber-500 justify-center items-center  text-white bg-red-700 rounded max-md:px-5  `}>
                      <div className="flex gap-3 items-center justify-center ">
                        <span>ADD TO CART</span>
                        <ImgComp
                          src={Images.shopping_cart}
                          className="shrink-0 my-auto w-5 aspect-square"
                        />

                      </div>
                    </button>
                    {/* <button className={` justify-center px-8 mybzb_text_clr rounded border border-red-700 border-solid max-md:px-5`}>
                    Buy now
                  </button> */}
                  </section>

                  <section className="flex gap-5 justify-between self-stretch max-md:flex-wrap">

                    {/* <ImgComp
                        src={Images.icn_wishlist}
                        className="shrink-0 self-stretch my-auto w-5 aspect-square"
                      /> */}

                    {data?.wishlist_status === true ?
                      <div

                        className="cursor-pointer flex gap-1.5 justify-center text-sm font-medium leading-5 text-neutral-900" aria-label="Add to Wishlist">
                        <i className="fa fa-heart text-red-800 text-base"
                          onClick={() => removeWishlistItem()}

                        ></i>
                        <span className="my-auto">Remove Wishlist</span>

                      </div>
                      :
                      <div

                        className="cursor-pointer flex gap-1.5 justify-center text-sm font-medium leading-5 text-neutral-900" aria-label="Add to Wishlist">
                        <i className="fa fa-heart-o text-red-800 text-base"
                          onClick={() => addWishlistItem()}
                        ></i>

                        <span className="my-auto">Add to Wishlist</span>
                      </div>
                    }


                    <div className="flex gap-3 justify-center ">
                      <span className="my-auto text-sm font-medium leading-5 text-neutral-900"> Share product: </span>
                      <div className="flex gap-2 justify-between items-center ">

                        <ImgComp
                          src={Images.icn_copy}
                          className="shrink-0 self-stretch my-auto w-5 aspect-square"
                        />

                        <button
                          type="button"
                          onClick={(e) => {
                            e.preventDefault();
                            window.open('https://www.facebook.com/mybzb', '_blank');
                          }}
                          className='focus:outline-none'
                        >
                          <ImgComp
                            src={Images.icn_facebook}
                            className="shrink-0 self-stretch my-auto w-5 aspect-square"
                          />
                        </button>

                        <button
                          type="button"
                          onClick={(e) => {
                            e.preventDefault();
                            window.open('https://twitter.com/my_bzb', '_blank');
                          }}
                          className='focus:outline-none'
                        >
                          <ImgComp
                            src={Images.icn_twitter}
                            className="shrink-0 self-stretch my-auto w-5 aspect-square"
                          />
                        </button>

                        <button
                          type="button"
                          onClick={(e) => {
                            e.preventDefault();
                            window.open('https://in.pinterest.com/my_bzb/', '_blank');
                          }}
                          className='focus:outline-none'
                        >
                          <ImgComp
                            src={Images.icn_pinterest}
                            className="shrink-0 self-stretch my-auto w-5 aspect-square"
                          />
                        </button>

                        <button
                          type="button"
                          onClick={(e) => {
                            e.preventDefault();
                            window.open('https://www.instagram.com/my_bzb?igsh=MWN6bmE3N3c5MmN1bQ==', '_blank');
                          }}
                          className='focus:outline-none'
                        >
                          <ImgComp
                            src={Images.icn_instagram}
                            className="shrink-0 self-stretch my-auto w-5 aspect-square"
                          />
                        </button>
                      </div>
                    </div>
                  </section>


                  <div className="flex gap-2 p-2 mt-6 text-xs leading-4 mybzb_text_clr bg-rose-50 rounded-lg font-[510] max-md:flex-wrap ">

                    <ImgComp
                      src={Images.offer_free_delivery}
                      className={`w-12`}
                    />
                    <div className="flex-1 my-auto max-md:max-w-full">
                      <span className="text-gray-800">
                        Don’t worry, we’ve a 10-day return policy on this product.
                      </span>{" "}
                      <span className="font-semibold mybzb_text_clr"> See Details </span>
                    </div>
                  </div>
                  <section className="grid lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-3 xs:grid-cols-3 grid-cols-3 flex justify-start items-center gap-5 my-6 bg-white rounded-lg border border-gray-200 border-solid max-md:flex-wrap p-2">
                    <div

                      className="flex h-auto flex-1 gap-3 justify-center max-sm:justify-between rounded-lg md:px-5 sm:px-5 max-sm:flex-col md:flex-col lg:flex-row xl:flex-row 2xl:flex-row"
                    >

                      <ImgComp
                        src={Images.frature_free_delivery}
                        className={`w-12 h-auto`}
                      />
                      <div className="my-auto max-sm:mt-0 text-xs font-medium leading-4 text-center text-neutral-400 max-sm:text-start md:text-start">
                        Free Delivery
                      </div>
                    </div>
                    <div
                      className="flex h-auto flex-1 gap-3 justify-center  max-sm:justify-between rounded-lg md:px-5 sm:px-5 max-sm:flex-col md:flex-col lg:flex-row xl:flex-row 2xl:flex-row"
                    >
                      <ImgComp
                        src={Images.return_policy}
                        className={`w-12 h-auto`}
                      />
                      <div className="my-auto max-sm:mt-0 text-xs font-medium leading-4 text-center text-neutral-400 max-sm:text-start md:text-start max-xs:text-[10px]">
                        10 Days Return Policy
                      </div>
                    </div>
                    <div
                      className="flex h-auto flex-1 gap-3 justify-center  max-sm:justify-between rounded-lg md:px-5 sm:px-5 max-sm:flex-col md:flex-col lg:flex-row xl:flex-row 2xl:flex-row"
                    >
                      <ImgComp
                        src={Images.cod}
                        className={`w-12 h-auto`}
                      />
                      <div className="my-auto max-sm:mt-0 text-xs font-medium leading-4 text-center text-neutral-400 max-sm:text-start md:text-start">
                        Pay on Cash
                      </div>
                    </div>
                  </section>


                </div>
              </div>
            </div>
          </div>
          <div className="flex gap-5 mt-5 max-md:flex-col ">
            <div className="flex gap-3 flex-col w-full">
              <InformationSection title="Jewellery Information"
              // infoItems={jewelleryInfo} 
              />
              <InformationSection title="About This item" infoItems={[]} />
              <InformationSection title="Additional Information" infoItems={[]} />
              <ImportantInformation />
              {hasProductReviews && (
                <section className="flex flex-col self-stretch px-4 py-6 bg-white rounded-xl border border-gray-200 border-solid max-w-[848px]">
                  <header className="flex gap-5 justify-between leading-[150%] max-md:flex-wrap max-md:max-w-full">
                    <h1 className="text-xl font-semibold text-neutral-900">Rating & Reviews</h1>
                    <div className="my-auto text-base font-medium text-red-700">See All</div>
                  </header>
                  <div className="flex gap-2 items-center mt-2 leading-[150%] max-md:flex-wrap">
                    <div className="flex gap-1 items-center self-stretch font-medium whitespace-nowrap">
                      <span className="self-stretch text-3xl text-neutral-900">{data?.product_reviews?.rating}</span>
                      <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/31decf9dec200a3baa4dc70eb3056ca103e983e0b1dc0ad51f85f3730910006b?apiKey=8e5983036f284a13866aae97819cdb73&" alt="Rating indicator" className="shrink-0 self-stretch my-auto aspect-[0.33] fill-neutral-400 w-[7px]" />
                      <span className="self-stretch my-auto text-2xl text-neutral-400">5</span>
                    </div>
                    <p className="self-stretch my-auto text-sm text-neutral-900">Overall Rating</p>
                    <p className="self-stretch my-auto text-xs text-neutral-400 max-md:max-w-full">{data?.rating_avg}k Rating</p>
                  </div>
                  <hr className="my-5" />
                  <UserReview
                    data={data}
                    name={data?.product_reviews?.user_name || ''}
                    date={data?.product_reviews?.date || ''}
                    // date={data?.product_reviews?.date || ''}
                    //date={data?.product_reviews && data?.product_reviews?.date ? data?.product_reviews?.date : ''}
                    avatarSrc={data?.product_reviews?.user_image || ''}
                    rating={data?.product_reviews?.rating || 0}
                    //avatarSrc="https://cdn.builder.io/api/v1/image/assets/TEMP/8d84ae6bbcc0a848670d66a58d8ac7c54988ffd089f8d3ad473b84105cd6509a?apiKey=8e5983036f284a13866aae97819cdb73&"
                    ratingSrc=""
                    text={data?.product_reviews?.comment || ''}
                    // images={[
                    //   "https://cdn.builder.io/api/v1/image/assets/TEMP/6ad04e448413f091a5c6102bcc0ce7a0e9c6dbdcffd6a728c0dd96232648dc46?apiKey=8e5983036f284a13866aae97819cdb73&",
                    //   "https://cdn.builder.io/api/v1/image/assets/TEMP/6ad04e448413f091a5c6102bcc0ce7a0e9c6dbdcffd6a728c0dd96232648dc46?apiKey=8e5983036f284a13866aae97819cdb73&",
                    //   "https://cdn.builder.io/api/v1/image/assets/TEMP/496a671eb786bd1c29cbd2774aa10954f40945be880392153dd6524b9875b871?apiKey=8e5983036f284a13866aae97819cdb73&"
                    // ]}
                    images={data?.product_reviews?.images}
                  />
                </section>
              )}
            </div>
            <section className="flex flex-col max-w-[400px]">
              <aside className="flex flex-col items-center p-6 w-full bg-white rounded-xl border border-gray-200 border-solid">
                <h1 className="text-2xl font-semibold text-red-700">Join us Follow Me</h1>
                <SocialIcons />
              </aside>
              <EmailForm />
            </section>
          </div>
          {data?.similar_items.length > 0 ?
            <SimilarProducts similarData={data?.similar_items} />
            :
            <>
            </>
          }

        </section>
      </main>
      {/* </div> */}
      <ToastContainer />
    </>
  );
}

export default ProductDetailContent;   