import React from "react";
import ImgComp from "../../../ReusableComp/ImgComp";
import * as Images from '../../../../assets';
import HeadingTextImageWrapper from "../../../ReusableComp/HeadingTextImageWrapper";
import { BgButton } from "../../../ReusableComp/Buttons";
import { useNavigate } from "react-router-dom";


const ExploreCollection = ({ width, data, title }) => {

    // console.log("ExploreCollection", data, title);
    const navigate = useNavigate();

    const ProductCard = ({ product_image, name, price, selling_price, discount, total_review, total_rating, id, rating_avg, discount_type }) => {

        const Star = ({ filling }) => {
            if (typeof filling === 'undefined' || filling === 1) {
                return <i className="fa fa-star text-amber-500" />;
            }

            if (filling === 0) {
                return <i className="fa fa-star-o text-amber-500" />;
            }

            if (filling === 0.5) {
                return (
                    <div className="half-star">
                        <i className="fa fa-star-half-o text-amber-500" />
                    </div>
                );
            }

            const width = filling * 100 + '%';

            return (
                <div className="star">
                    <div className="fill" style={{ width }}>
                        <i className="fa fa-star text-amber-500" />
                    </div>
                    <div className="empty">
                        <i className="fa fa-star-o text-amber-500" />
                    </div>
                </div>
            );
        };

        const StarRating = ({ value }) => {
            return (
                <div className="rating flex items-center gap-1">
                    {Array.from(Array(5)).map((_, index) => {
                        const starSerialNumber = index + 1;

                        if (starSerialNumber <= Math.floor(value)) {
                            return <Star key={starSerialNumber} filling={1} />;
                        }

                        if (starSerialNumber > Math.ceil(value)) {
                            return <Star key={starSerialNumber} filling={0} />;
                        }

                        const filling = value - index;
                        //  if (filling > 0 && filling < 1) {
                        //     return <Star key={starSerialNumber} filling={filling} />;
                        //   }

                        return <Star key={starSerialNumber} filling={filling} />;
                    })}
                </div>
            );
        };

        return (
            <div className="flex flex-col  max-md:ml-0 max-md:w-full h-full cursor-pointer"
                onClick={() => navigate('/product_detail', { state: { id } })}
            >
                <div
                    className="relative flex justify-between flex-col grow mx-auto w-full bg-white rounded-lg border border-gray-300 border-solid hover:shadow-2xl hover:border-0 max-md:pl-5 max-md:mt-6"
                // className="relative flex justify-between flex-col grow mx-auto w-full bg-white rounded-lg border border-gray-300 border-solid hover:shadow-2xl hover:border-0 max-md:pl-5 max-md:mt-6"
                >
                    {/* {discount !== 0 && (
                        <div className="text-base font-medium bg-red-700 text-white absolute px-3 py-1 right-0 rounded-bl-lg">
                            {discount}
                        </div>
                    )} */}
                    {(discount > 0 || discount === "") && (
                        <div className="text-base font-medium bg-red-700 text-white absolute px-3 py-1 right-0 rounded-bl-lg">
                            {/* {data?.discount}% off */}
                            {discount_type === 'flat' || discount_type === "" ? (
                                <span>
                                    {discount < 0 ? '' : 'Flat ₹'}
                                    {Math.abs(discount)}
                                </span>
                            )
                                :

                                <span>

                                    {Math.abs(discount)}
                                    {discount < 0 ? '' : '%'}
                                </span>
                            }
                        </div>
                    )}

                    <div className=" p-5 flex  flex-col h-full ">
                        <div
                        // className={`max-sm:w-40 md:w-48 lg:w-48 lg:h-40 max-sm:mx-auto xl:w-full  xl:h-60 2xl:w-full 2xl:h-60`}
                        >
                            <ImgComp
                                src={product_image}
                                alt={name}
                                className="object-contain xl:w-full mx-auto xl:h-60 2xl:w-full 2xl:h-60  max-sm:w-40 md:w-48 md:h-48 lg:w-48 lg:h-40 max-sm:mx-auto"
                            // className={`max-sm:w-40 md:w-96 lg:w-96 max-sm:mx-auto`}
                            // className={`max-sm:w-40 md:w-48 lg:w-48 lg:h-40 max-sm:mx-auto xl:w-full  xl:h-60 2xl:w-full 2xl:h-60 `}
                            />
                        </div>
                        <div className="  flex  flex-col h-full pt-0">
                            <h3 className="mt-2.5 text-xl font-semibold text-ellipsis text-neutral-900 md:text-base">
                            {/* Aureole Nose Ring */}
                                {name}
                            </h3>
                            <div className="flex gap-2 justify-between mt-2">
                                <div className="text-lg text-neutral-900 md:text-base">
                                    {price !== selling_price &&
                                        <>
                                            <span className="line-through text-neutral-400">
                                                {price?.toLocaleString('en-US')}
                                            </span>{" "} &nbsp;
                                            <span className="line-through text-neutral-400">₹</span>{" "}
                                        </>
                                    }
                                    <span className="font-[510] text-neutral-900">
                                        {selling_price?.toLocaleString('en-US')} ₹</span> </div>
                                {/* <div className="text-base font-medium text-green-600">
                                    {discount} off
                                </div> */}
                            </div>
                            <div className="flex gap-2 justify-between mt-2">

                                {/* <StarRating value={total_review} /> */}
                                <StarRating value={rating_avg} />

                                {(total_rating !== 0 || total_rating === "0" || total_rating === "") && (

                                    <div className="text-base leading-6 text-center text-neutral-400">
                                        ({total_rating}) reviews
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    const collectionItems = [
        {
            id: 1,
            imageSrc: `${Images.home_product_ring}`,
            // imageSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/55ca3e7f71e333868a9d9a7b3036673b1acff039d139d5d51d5140d3ce5000dd?apiKey=8e5983036f284a13866aae97819cdb73&", 
            name: "Mossinate Ring Silver Diamond Silver Plated Ring", originalPrice: "59,000", discountedPrice: "47,200", discount: "25% off", rating: 4.1,
        },
        {
            id: 2,
            imageSrc: `${Images.home_product_ring}`,
            // imageSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/55ca3e7f71e333868a9d9a7b3036673b1acff039d139d5d51d5140d3ce5000dd?apiKey=8e5983036f284a13866aae97819cdb73&", 
            name: "Mossinate Ring Silver Diamond Silver Plated Ring", originalPrice: "59,000", discountedPrice: "47,200", discount: "25% off", rating: 4.1,
        },
        {
            id: 3,
            imageSrc: `${Images.home_product_ring}`,
            //  imageSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/55ca3e7f71e333868a9d9a7b3036673b1acff039d139d5d51d5140d3ce5000dd?apiKey=8e5983036f284a13866aae97819cdb73&", 
            name: "Mossinate Ring Silver Diamond Silver Plated Ring", originalPrice: "59,000", discountedPrice: "47,200", discount: "25% off", rating: 4.1,
        },
        {
            id: 3,
            imageSrc: `${Images.home_product_ring}`,
            //  imageSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/55ca3e7f71e333868a9d9a7b3036673b1acff039d139d5d51d5140d3ce5000dd?apiKey=8e5983036f284a13866aae97819cdb73&", 
            name: "Mossinate Ring Silver Diamond Silver Plated Ring", originalPrice: "59,000", discountedPrice: "47,200", discount: "25% off", rating: 4.1,
        },
    ];
    return (
        <section className="flex flex-col items-center py-10 bg-rose-50">
            <div className={`flex flex-col self-center px-5 w-full max-w-${width} max-md:max-w-full`}>
                {/* <h2 className="text-lg font-semibold tracking-wider text-center text-red-700 uppercase max-md:max-w-full"> 
                Basic and Exquisite </h2> */}
                <div className="flex gap-2 justify-between w-full max-md:flex-wrap max-md:max-w-full">
                    {/* <h1 className="text-5xl font-medium capitalize text-neutral-900 max-md:max-w-full max-md:text-4xl"> 
                    Explore the Collection </h1> */}
                    <HeadingTextImageWrapper
                        // text="Explore the Collection"
                        // text="Trending Items"
                        text={title}
                        className="w-auto text-left"
                        textClassName='pe-10 ps-5'
                        alignTextWrapperClassName="text-left"
                    />
                    <div className="flex gap-3 my-auto" onClick={() => navigate('/listing')} >

                        <BgButton name={`See all`} />
                    </div>
                </div>
                <div className="mt-12 max-md:mt-10 max-md:max-w-full">
                    <div
                        // className="flex gap-5 max-md:flex-col max-md:gap-0 grid max-2xl:grid-cols-4 max-lg:grid-cols-3 max-md:grid-cols-2 max-sm:grid-cols-1 grid-cols-4 md:gap-3 max-sm:gap-3"
                        className="flex gap-5 max-md:flex-col grid max-2xl:grid-cols-4 max-lg:grid-cols-3 max-md:grid-cols-2  max-sm:grid-cols-1 grid-cols-4 max-sm:gap-0 md:gap-3"
                    >
                        {/* <div className="flex flex-col w-3/12 max-md:ml-0 max-md:w-full">
                            <div className="flex overflow-hidden relative flex-col px-6 pt-6 pb-12 font-semibold text-center text-white aspect-[0.75] max-md:px-5 max-md:mt-6">
                                <ImgComp
                                    src={Images.home_explore_collection}
                                    alt={''}
                                    className={'object-cover absolute inset-0 size-full'}
                                />

                                <h3 className="relative text-2xl">Explore Collection</h3> <p className="relative mt-64 text-base max-md:mt-10"> Lorem ipsum dummy text dorem typetsrtting </p> </div>
                        </div> */}
                        {/* {collectionItems.map((item) => (<CollectionItem key={item.id} {...item} />))} */}
                        {data && data?.length > 0 && data.slice(0, 4).reverse().map((card, index) => (
                            <ProductCard key={index} {...card} />
                        ))}
                    </div> </div>
            </div>
            {/* <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/092c264cbf824c4fcc2499c7a9818d8d75a4b534550b16983c2a9e717e315b73?apiKey=8e5983036f284a13866aae97819cdb73&" alt="" className="mt-10 max-w-full aspect-[3.13] w-[183px] max-md:mt-10" /> */}
        </section>
    );
}


export default ExploreCollection