
import { toast } from 'react-toastify';

const toastPromise = (promise, messages) => {
  return toast.promise(promise, {
    pending: {
      render() {
        return messages.pending || "Processing...";
      },
      position: 'top-center',
      autoClose: false,
    },
    // success: {
    //   render({ data }) {
    //     return messages.success(data) || "Operation successful!";
    //   },
    //   position: 'top-center',
    //   autoClose: 3000,
    // },
    success: {
      render({ data }) {
        if (data.status === false) {
          throw new Error(data.message);
        }
        return data.message || "OTP sent successfully!";
      },
      position: 'top-center',
      autoClose: 2000,
    },
    error: {
      render({ data }) {
        return messages.error(data) || "An error occurred.";
      },
      position: 'top-center',
      autoClose: 2000,
    }
  });
};

export default toastPromise;
