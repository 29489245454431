import React, { useEffect, useState } from 'react'
import { fetchWithFormDataToken } from '../../../../../apiGeneralFunction';
import { useLocation, useNavigate } from 'react-router-dom';
import Layout from '../../../../ReusableComp/Layout';
import ImgComp from '../../../../ReusableComp/ImgComp';
import { TwoBreadcrumb } from '../../../../ReusableComp/Breadcrumb';
import { width } from '../../../../ReusableComp/Buttons';

const AllCategoryListpage = () => {

    const navigate = useNavigate();

    // const location = useLocation();

    // const { categoryId,seller_id } = location.state;

    const [data, setData] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        fetchData();
    }, [])

    const fetchData = async () => {

        const tokenValue = localStorage?.getItem('login api response token');

        const storedToken = JSON?.parse(tokenValue);
        if (!storedToken) {
            navigate('/');
            // return;
        }

        const abortController = new AbortController();

        try {
            const formdata = new FormData();
            formdata.append("device_type", "web");

            const result = await fetchWithFormDataToken(`api/v1/category_list`, formdata, abortController, null);
            if (result?.status === true) {
                setData(result?.data)
            }
            else {

            }
        } catch (error) {

        }
        finally {

            abortController.abort();
        }
    };

    // console.log("category_list", data);

    return (
        <Layout>
            <TwoBreadcrumb
                href3={`/`}
                name3={`Top Categories`}
            />
            <div className="flex flex-col py-20">
                <div className={`flex flex-col items-center self-center px-5 mt-5 w-full max-w-${width} max-md:max-w-full`}>
                    <div className='flex grid grid-cols-6 w-full flex-col gap-5'>
                        {data && data?.length > 0 && data?.map((item, index) => (
                            <>
                            <ImgComp src={item?.categoryImage} key={index}
                                className='border rounded-md'
                            />
                            </>
                        ))}
                    </div>
                </div>
            </div>

        </Layout>
    )
}

export default AllCategoryListpage