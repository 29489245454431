import React from 'react';
import * as Images from '../../../../assets';
import ImgComp from '../../../ReusableComp/ImgComp';
import { width } from '../../../ReusableComp/Buttons';
import HeadingTextImageWrapper from '../../../ReusableComp/HeadingTextImageWrapper';

const PrivacyImgSection = () => {
    return (
        <>
            <div className="bg-rose-50">
            <div className={`w-full max-w-${width} max-md:max-w-full mx-auto py-5`}>
                <div className='flex '>
                    <div className='my-auto'>
                        <div className='mybzb_text_clr font-bold uppercase mb-3'>
                            Privacy Policy
                        </div>
                        <div className="my-2  text-4xl font-bold capitalize text-neutral-900 max-md:text-3xl  home_heading  ">
                            Safeguarding Your Data:
                        </div>
                        <HeadingTextImageWrapper
                        // text=" Our Products" 
                        // text=" Featured Item"
                        text={`Our Privacy Policy`}
                        className="w-auto "
                        textClassName=' '
                        alignTextWrapperClassName="text-left"
                        />
                    
                        <div className="mt-6 text-lg  max-md:max-w-full">
                        Welcome to{" "}
                            <span className="font-semibold text-red-700">
                            My Bharatiya Zaveri Bazaar (MyBzB)
                                </span>
                            , where your privacy and trust are of paramount importance to us. This
                            Privacy Policy outlines the information we collect, how we use it, and
                            the measures we take to protect your data.
                        </div>
                    </div>    
                    <div>
                        <ImgComp
                        src={Images.privacy_policy}
                        />
                    </div>
                </div>
            </div>
            </div>
        </>
    )
}

export default PrivacyImgSection