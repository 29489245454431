import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { TwoBreadcrumb } from '../../../../ReusableComp/Breadcrumb'
import { BgButton, OutlineButton, width } from '../../../../ReusableComp/Buttons';
import ImgComp from '../../../../ReusableComp/ImgComp';
import * as Images from '../../../../../assets';
import { Link, useNavigate } from 'react-router-dom';
import { fetchWithFormDataToken } from '../../../../../apiGeneralFunction';
import { AddressModalBox, PaymentFail, PaymentSuccess, Thankyou } from '../../../../ReusableComp/PaymentResponseModalBox';
import ViewShoppingCart from '../../ViewShoppingCart';
import useRazorpay from "react-razorpay";
import { toBeRequired } from '@testing-library/jest-dom/matchers';
import toastPromise from '../../../../../services/toastService';

const ShoppingCartContent = ({ }) => {

  const [Razorpay] = useRazorpay();
  const navigate = useNavigate();

  // console.log("ShoppingCartContent width", width);

  const [data, setData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalCodOpen, setIsModalCodOpen] = useState(false);
  const [isModalFailOpen, setIsModalFailOpen] = useState(false);
  const [isCartEmpty, setIsCartEmpty] = useState(false);
  const [address, setAddress] = useState();
  const [note, setNote] = useState();
  const [showNote, setShowNote] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const [successData, setSuccessData] = useState();
  const [isAddressOpen, setIsAddressOpen] = useState(false);
  const [addressId, setAddressId] = useState(null);
  const [isPaymentMethod, setIsPaymentMethod] = useState(false);

  const [addressData, setAddressData] = useState([]);


  useEffect(() => {
    addressCardFetchData();
  }, []);


  const addressCardFetchData = async () => {

    const tokenValue = localStorage?.getItem('login api response token');
    const storedToken = JSON?.parse(tokenValue);
    if (!storedToken) {
      navigate('/register');
      // return;
    }

    const user_id = localStorage?.getItem('user_data');
    const userId = JSON.parse(user_id);

    try {

      const formdata = new FormData();
      formdata.append("user_id", userId?.user_id);

      const result = await fetchWithFormDataToken(`api/v1/list_user_address`, formdata, null);
      if (result?.status === true) {
        setAddressData(result?.data)
      }
      else {

      }
    } catch (error) {

    }
  };



  // console.log("selectedValue", selectedValue, note);

  const tokenValue = localStorage?.getItem('login api response token');
  const storedToken = JSON?.parse(tokenValue);
  // console.log("storedToken ShoppingCartContent", storedToken, tokenValue);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    fetchData();
  }, [])

  const fetchData = async () => {
    const tokenValue = localStorage?.getItem('login api response token');

    const storedToken = JSON?.parse(tokenValue);
    if (!storedToken) {
      navigate('/register');
      // return;
    }

    const user_id = localStorage?.getItem('user_data');
    const userId = JSON.parse(user_id);

    const abortController = new AbortController();

    try {


      const formdata = new FormData();
      formdata.append("user_id", userId?.user_id);
      //formdata.append("user_id", "50");

      const result = await fetchWithFormDataToken(`api/v1/product_list_cart_item`, formdata, abortController, null);
      // console.log("shopping cart", result);
      if (result?.status === true) {
        setData(result?.data);
        setIsCartEmpty(false);
        setAddress(result?.data?.default_address)
      }
      else {
        setData();
        setIsCartEmpty(true);
        // setData(result?.message)
      }
    } catch (error) {

    }
    finally {

      abortController.abort();
    }
  };

  // console.log("ShoppingCartContentdata", data?.message);


  const ProductItem = ({ product, fetchData }) => {

    const [quantity, setQuantity] = useState(product?.qty || 1);
    const [productId, setProductId] = useState();
    const [price, setPrice] = useState(product?.selling_price);

    const handleIncrement = () => {
      const newQuantity = quantity + 1;
      setQuantity(newQuantity);
      // setPrice(newQuantity * product?.selling_price);
      // console.log("ProductItem", newQuantity, product?.price);
      updateCart(newQuantity);
    }

    const handleDecrement = () => {
      const newQuantity = quantity - 1;
      if (newQuantity >= 1) {
        setQuantity(newQuantity);
        // setPrice(newQuantity * product?.selling_price);
      }
      updateCart(newQuantity);
    }

    const updateCart = async (newQuantity) => {
      const tokenValue = localStorage?.getItem('login api response token');

      const storedToken = JSON?.parse(tokenValue);
      if (!storedToken) {
        navigate('/register');
        // return;
      }

      const user_id = localStorage?.getItem('user_data');
      const userId = JSON.parse(user_id);

      const abortController = new AbortController();

      try {


        const formdata = new FormData();
        formdata.append("id", product?.id);
        formdata.append("quantity", newQuantity);

         const result = await fetchWithFormDataToken(`api/v1/product_update_to_cart`, formdata, abortController, null);
         if (result?.status === true) {
          //  toast.success(result?.message, {
          //    position: 'top-center',
          //    autoClose: 5000,
          //  });
           fetchData();
         }
         else {
         }
        // const response = await toastPromise(
        //   fetchWithFormDataToken('api/v1/product_update_to_cart', formdata, abortController, null),
        //   {
        //     pending: "Updating Product...",
        //     success: (data) => data.message || "Product is updated",
        //     error: (data) => data.message || "Failed to update product",
        //   }
        // );

        // if (response.status === true) {
        //   fetchData();
        // }
      } catch (error) {

      }
      finally {

        abortController.abort();
      }
    }

    const deleteItem = async () => {
      const tokenValue = localStorage?.getItem('login api response token');

      const storedToken = JSON?.parse(tokenValue);
      if (!storedToken) {
        navigate('/register');
        // return;
      }

      const user_id = localStorage?.getItem('user_data');
      const userId = JSON.parse(user_id);

      const abortController = new AbortController();

      try {

        const formdata = new FormData();
        formdata.append("id", product?.id);

        const result = await fetchWithFormDataToken(`api/v1/product_delete_to_cart`, formdata, abortController, null);

        if (result?.status === true) {
          //  toast.success(result?.message, {
          //    position: 'top-center',
          //    autoClose: 2000,
          //  });
          fetchData();
        }
        else {

        }
        // const response = await toastPromise(
        //   fetchWithFormDataToken('api/v1/product_delete_to_cart', formdata),
        //   {
        //     pending: "Deleting Product...",
        //     success: (data) => data.message || "Product is deleted",
        //     error: (data) => data.message || "Failed to delete Product",
        //   }
        // );

        // if (response.status === true) {
        //   fetchData();
        // }

      } catch (error) {

      }
      finally {

        abortController.abort();
      }
    };

    // console.log("ProductItem  productid", product?.id, quantity);

    // const ImageComponent = ({ Images }) => {
    //   return (
    //     <ImgComp
    //       src={product?.product_image || Images.home_product_ring}
    //       className='w-40 rounded-lg border max-sm:h-36'
    //     />
    //   )
    // }

    return (
      <>
        <section className="flex gap-4 max-md:flex-wrap max-md:mt-5 relative">
        <ImgComponent
              src={product?.product_image || Images.home_product_ring}
               className='w-40 rounded-lg border max-sm:h-36'
              alt={`home_product_ring`}
            />
          {/* <img loading="lazy" src={product.image} alt={product.alt} className="shrink-0 max-w-full border border-gray-200 border-solid aspect-square w-[124px]" /> */}
          <article className="flex flex-1 gap-4 justify-between max-md:flex-wrap ">
            <div className="flex flex-col my-auto max-md:max-w-full">
              <h2 className="text-lg font-medium text-zinc-900 max-md:max-w-full">{product?.name}</h2>
              {/* <div className="flex gap-5 justify-between mt-2 text-sm text-zinc-900 max-md:flex-wrap">
                <p>
                  <span className="leading-5 text-gray-500">Brand: </span>
                  <span className="font-semibold leading-5 text-zinc-900">{product.brand}</span>
                </p>
                <p>
                  <span className="leading-5 text-gray-500">Category: </span>
                  <span className="font-semibold leading-5 text-zinc-900">{product.category}</span>
                </p>
                <p>
                  <span className="leading-5 text-gray-500">Sku: </span>
                  <span className="font-semibold leading-5 text-zinc-900">{product.sku}</span>
                </p>
                <p className="text-emerald-500">
                  <span className="leading-5 text-gray-500">Availability: </span>
                  <span className="font-semibold leading-5 text-emerald-500">{product.availability}</span>
                </p>
              </div> */}
              <div className="flex gap-5 justify-between self-start mt-2 max-md:flex-col">
                {product?.weight && product?.weight?.trim() !== "0" && (
                  <p className="text-sm text-zinc-900">
                    <span className="leading-5 text-gray-500">Weight: </span>
                    <span className="font-semibold leading-5 text-zinc-900">{product?.weight}</span>
                  </p>
                )}
                {product?.size && product?.size?.trim() !== "0" && (
                  <div className="flex gap-2 whitespace-nowrap">
                    <span className="text-sm text-gray-500">Size:</span>
                    <span className="justify-center px-3 py-1 text-xs leading-4 text-red-700 bg-rose-50 rounded-[360px]">{product.size}</span>
                  </div>
                )}

              </div>
              <div className="flex gap-4 self-start mt-2">
                <div className="flex gap-2 whitespace-nowrap leading-[150%]">
                  <p className="text-lg font-bold text-red-700">₹  {Number(price).toLocaleString('en-IN')}</p>
                  {price !== product?.price &&
                    <p className="my-auto text-sm text-neutral-400 line-through">₹  {Number(product?.price).toLocaleString('en-IN')}</p>
                  }
                  {/* <p className="my-auto text-sm text-neutral-400 line-through">₹  {Number(product?.price).toLocaleString('en-IN')}</p> */}
                </div>
                {/* <span className="justify-center p-1 my-auto text-xs font-medium text-emerald-500 rounded-lg bg-green-600 bg-opacity-10">{product.discount}</span> */}
                {product?.discount > 0 && (
                  <div className="justify-center p-1 my-auto text-xs font-medium text-emerald-500 rounded-lg bg-green-600 bg-opacity-10">
                    {product?.discount_type === 'flat' ? (
                      <span>
                        {product?.discount < 0 ? '' : 'Flat ₹'}
                        {Math.abs(product?.discount)} off
                      </span>
                    )
                      :

                      <span>

                        {Math.abs(product?.discount)}
                        {product?.discount < 0 ? '' : '%'} off
                      </span>
                    }
                  </div>
                )}
              </div>
            </div>
            <aside className="flex flex-col justify-end">
              {/* <div className="flex gap-3 ml-5 max-md:ml-2.5 items-center justify-end cursor-pointer"
                onClick={() => deleteItem()}
              >
                <ImgComp
                  src={Images.icn_delete}
                  className="shrink-0 w-5 h-5 aspect-square"
                />

              </div> */}
              <div className="flex flex-col justify-center p-2 mt-16 text-xs font-semibold text-center whitespace-nowrap rounded border border-gray-200 border-solid text-neutral-900 max-md:mt-0">
                <div className="flex gap-3 justify-between">
                  <div onClick={() => handleDecrement()}>
                    <i className="fa fa-minus text-xs"></i>
                  </div>
                  <span>{quantity}</span>
                  <div onClick={handleIncrement}>
                    <i className="fa fa-plus text-xs"></i>
                  </div>

                </div>
              </div>
            </aside>
          </article>
          <div className="absolute top-0 right-0 flex gap-3 ml-5 max-md:ml-2.5 items-center justify-end cursor-pointer"
            onClick={() => deleteItem()}
          >
            <ImgComp
              src={Images.icn_delete}
              className="shrink-0 w-5 h-5 aspect-square"
            />

          </div>
        </section>
        <hr className='my-5 ' />
      </>
    );
  }


  const isEmptyObject = (obj) => {
    return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
  };

  const defaultAddress = data?.default_address;


  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://checkout.razorpay.com/v1/checkout.js';
    script.async = 'true'
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    }
  }, []);


  const checkPayMethod = () => {
    // console.log("checkPayMethod selectedValue", selectedValue);
    if (!selectedValue) {
      toast.error("Please select an order type.", {
        position: 'top-center',
        autoClose: 3000,
      });
      return;
    }
    else {
      handlePayment()
    }
    // if (selectedValue === "0") {
    //   let paymentId = ""
    //   placeOrder(paymentId)
    // } else {
    //   handlePayment()
    // }
  }

  const handlePayment = async () => {

    const user_id = localStorage?.getItem('user_data');
    const userId = JSON.parse(user_id);
    const options = {
      key: 'rzp_test_nqF5dOkbjWufx8',
      amount: 1 * 100,
      currency: 'INR',
      name: 'MyBzb',
      image: Images.logo,
      description: 'Test Transaction',
      // email: '',
      order_id: "",
      handler: function (response) {
        // console.log("response.razorpay_payment_id", response?.razorpay_payment_id, response);
        // alert(response.razorpay_payment_id);
        placeOrder(response?.razorpay_payment_id);

      },
      prefill: {

        contact: '1234567890',
        // contact: phoneNo,

      },
      notes: {
        address: 'Your address',
      },
      theme: {
        color: '#AC3533',
      },

    }

    const rzp = new window.Razorpay(options);
    // console.log("rzp response options", rzp, options);
    rzp.open();
    // return new Promise((resolve, reject) => {
    //   rzp.on('payment.success', (response) => {
    //     console.log("payment.success",response.razorpay_payment_id);
    //     resolve(response.razorpay_payment_id);
    //   });
    //   rzp.on('payment.error', (error) => {
    //     reject(error);
    //   });
    // });
  }


  const placeOrder = async (paymentId) => {

    const tokenValue = localStorage?.getItem('login api response token');
    const storedToken = JSON?.parse(tokenValue);
    if (!storedToken) {
      navigate('/');
      // return;
    }

    const user_id = localStorage?.getItem('user_data');
    const userId = JSON.parse(user_id);

    const abortController = new AbortController();

    try {

      const formdata = new FormData();
      formdata.append("user_id", userId?.user_id);
      formdata.append("note", note || '');
      formdata.append("payment_id", paymentId || '');
      formdata.append("address_id", addressId);
      formdata.append("order_type", selectedValue || '');

      const result = await fetchWithFormDataToken(`api/v1/place_order`, formdata, abortController, null);
      // console.log("place order", result?.data?.payment_id);
      if (result?.status === true) {
        if (result?.data?.payment_id === null) {
          setIsModalCodOpen(true)

        } else {
          setIsModalOpen(true)
          setSuccessData(result?.data)
          setIsAddressOpen(false)
          setIsPaymentMethod(false)
        }
      }
      else {
        if (selectedValue === 0) {
          setIsModalCodOpen(true)
        } else {
          setIsModalFailOpen(true)
        }

      }
    } catch (error) {

    }
    finally {

      abortController.abort();
    }
  };

  const failedButton = () => {
    return (
      <>
        <div>
          <BgButton name={`Retry`} />
        </div>
        <OutlineButton name={`Cancel`} />
      </>
    )
  }

  // console.log("addressData", addressData.length);

  const ImgComponent = ({ src, alt, className }) => {
    return <img src={src} alt={alt} className={className} />;
  }

  const AddressModalList = ({ addressId }) => {

    const [shouldFetch, setShouldFetch] = useState(true);

    const handleAddressChange = useCallback((id) => {
      setAddressId(id);
      setShouldFetch(false);
    }, []);

    const AddressCard =  React.memo(({ onAddressChange, addressId, addressAddData, default_address, flatno_building, address_type, onEdit, onDelete, onSetDefault, street_area, landmark, city, state, country, pincode }) => {
      // const address = flatno_building+ "," + street_area + "," + landmark + "," + city + "," + state + "," + country + "," + pincode
      // console.log("AddressCardaddressData", addressAddData?.default_address, default_address);


      const address = [
        flatno_building || '',
        street_area || '',
        landmark || '',
        city || '',
        state || '',
        country || '',
        pincode || ''
      ].filter(part => part.trim() !== '').join(', ');


      // const ImgComponent = React.memo(({ Images, alt }) => {
      //   return (
      //     <ImgComp
      //       src={Images}
      //       className='w-5'
      //       alt={alt}
      //     />
      //   )
      // })

      // const renderContent = useMemo(() => {
      //   const Home = () => (
      //     <div className="flex items-center gap-2">
      //       <ImgComponent Images={Images.icn_home_black} key='home'/>
      //       <div>Home</div>
      //     </div>
      //   );

      //   const Office = () => (
      //     <div className="flex items-center gap-2">
      //       <ImgComponent Images={Images.icn_office_black} key='office' />
      //       <div>Office</div>
      //     </div>
      //   );

      //   const Others = () => (
      //     <div className="flex items-center gap-2">
      //       <ImgComponent Images={Images.icn_location_black} key='others'/>
      //       <div>Others</div>
      //     </div>
      //   );

      //   switch (address_type) {
      //     case '1':
      //       return <Home />;
      //     case '2':
      //       return <Office />;
      //     case '3':
      //       return <Others />;
      //     default:
      //       return null;
      //   }
      // }, [address_type, Images]);


     
      
      const Home = () => {
        return (
          <div className='flex items-center gap-2'>
            <ImgComponent
              src={Images.icn_home_black}
              className='w-5'
              alt={`home`}
            />

            <div>Home</div>
          </div>
        )
      }

      const Office = () => {
        return (
          <div className='flex items-center gap-2'>
            <ImgComponent
              src={Images.icn_office_black}
              className='w-5'
              alt={`office`}
            />
            {/* <ImgComponent
              Images={Images.icn_office_black}
              key='office'
            /> */}
            <div>Office</div>
          </div>
        )
      }

      const Others = () => {
        return (
          <div className='flex items-center gap-2'>
            <ImgComponent
              src={Images.icn_location_black}
              className='w-5'
              alt={`others`}
            />
            {/* <ImgComponent
              Images={Images.icn_location_black}
              key='others'
            /> */}
            <div>Others</div>
          </div>
        )
      }

      const renderContent = (title) => {
        switch (title) {
          case '1':
            return <Home />;
          case '2':
            return <Office />;
          case '3':
            return <Others />;
          default:
            return null;
        }
      }
      // const RenderContent = useCallback(({address_type}) => {
      //   switch (address_type) {
      //     case '1':
      //       return (
      //         <div className="flex items-center gap-2">
      //           <ImgComponent Images={Images.icn_home_black} alt="home"/>
      //           <div>Home</div>
      //         </div>
      //       );
      //     case '2':
      //       return (
      //         <div className="flex items-center gap-2">
      //           <ImgComponent Images={Images.icn_office_black} alt="office"/>
      //           <div>Office</div>
      //         </div>
      //       );
      //     case '3':
      //       return (
      //         <div className="flex items-center gap-2">
      //           <ImgComponent Images={Images.icn_location_black} alt="others"/>
      //           <div>Others</div>
      //         </div>
      //       );
      //     default:
      //       return null;
      //   }
      // },[address_type])


      // const radioChange = (e) => {
      //   console.log("radioChange",e.target.value);
      //   // setAddressId(addressAddData?.id)
      //   setAddressId(e.target.value)
      // }
      // const handleRadioChange = useCallback((e) => {
      //   const selectedId = e.target.value;
      //   setAddressId(selectedId);

      // }, []);
      //  const handleRadioChange = (e) => {
      //   e.preventDefault();
      //    const selectedId = e.target.value;
      //    setAddressId(selectedId);
      //    setShouldFetch(false); 

      //  };

      // const handleRadioChange = useCallback((addressId) => {
      //   setAddressId(addressId);
      //   setShouldFetch(false); 
      // },[shouldFetch]);

      // const handleRadioChange = (e) => {
      //   onAddressChange(e.target.value);

      // };

      // const handleRadioChange = (id) => {
      //   setAddressId(id);
      //   setShouldFetch(false);
      // };

      // const handleRadioChange = () => {
      //   setShouldFetch(false);
      //   onAddressChange(addressAddData?.id);

      // }
      const handleRadioChange = () => {
        setShouldFetch(false);
        onAddressChange(addressAddData?.id);
      }



      return (
        <section
          key={addressAddData?.id}
          className="flex flex-row max-sm:items-start justify-start p-4 mt-4 text-xs leading-5 bg-white rounded-lg border border-gray-200 border-solid w-full items-center gap-3 "
          onClick={() => setShouldFetch(false)}
        >
          {/* {addressId === addressAddData?.id ?
            <i className='fa fa-dot-circle-o mybzb_text_clr text-sm'
              onClick={handleRadioChange}
            ></i>
            :
            <i className='fa fa-circle-o mybzb_text_clr' onClick={handleRadioChange}></i>
          } */}

          <input
            type="radio"
            id={addressAddData?.id}
            name={addressAddData?.id}
            // name="address"
            value={addressAddData?.id}
            checked={addressId === addressAddData?.id}
            // checked={addressId === addressAddData?.id  && setShouldFetch(false)}
            // onClick={(e) => {
            //   e.preventDefault();
            //   onAddressChange(e.target.value);
            //   setAddressId(addressAddData?.id);
            //   setShouldFetch(false);
            // }}
            //  onChange={(e) => {
            //   e.preventDefault();
            //   setShouldFetch(false);
            //    setAddressId(addressAddData?.id);

            //  }}
            // onClick={() => handleRadioChange(addressAddData?.id)}
            //  onChange={() => setAddressId(addressAddData?.id)}
            onChange={handleRadioChange}
            //  onChange={() => handleAddressChange(addressAddData?.id)}
            className='max-sm:mt-2 focus:border-0 focus:outline-0 focus:ring-0'
          />

          <div className='flex flex-row w-full'>
            <div className='w-full'>
              <div className="flex gap-2 self-start text-base font-medium whitespace-nowrap text-neutral-900">
                <div>{renderContent(address_type)}</div>

              </div>
              <div className="mt-2 text-neutral-400 text-start">{address}</div>
            </div>
            {default_address === "1" &&
              <div className='text-amber-500 whitespace-nowrap text-xs font-bold'>
                <i className="fa fa-check border border-amber-500 rounded-full px-0.5 py-0.5 me-2"></i>
                Set as Default
              </div>
            }
          </div>
        </section>
      );
    })

    const handleContinueClick = () => {
      if (!addressId) {
        toast.error("Please select an address before proceeding.", {
          position: 'top-center',
          autoClose: 2000,
        });
        return;
      }
      setIsPaymentMethod(true);
      setIsAddressOpen(false);
    };

    const AddressButton = () => {
      return (
        <div className="flex gap-4">
          <Link
            to={`/profile`}
            state={{ openAddressTab: true }}
          >
            <button
              type="button"
              className="focus:border-red-700 focus:outline-0 focus:ring-0 justify-center px-12 py-3.5 text-red-700 whitespace-nowrap rounded-lg border border-red-700 border-solid max-md:px-5"
            >
              Add Address
            </button>
          </Link>

          <button
            type="button"
            className="focus:border-red-700 focus:outline-0 focus:ring-0 justify-center px-8 py-3.5 text-white bg-red-700 rounded-lg max-md:px-5"
            // onClick={() => setIsPaymentMethod(true) && setIsAddressOpen(false)}
            onClick={handleContinueClick}
          >
            Continue
          </button>
        </div>
      )
    }



    return (
      <>

        {/* {data?.length > 0 ?
          <> */}
        <div className='flex justify-betweeen flex-col max-h-full h-full ' >
          <div className='max-h-full h-full overflow-y-auto ' >
            {addressData?.length > 0 && addressData?.map((addressData, index) => (
              <AddressCard key={index}
                {...addressData}
                addressAddData={addressData}
                addressId={addressId}
                onAddressChange={handleAddressChange}

              />
            ))}
          </div>

          <div className="flex flex-col justify-center items-end mt-8 text-base font-semibold leading-6 max-md:pl-5 max-md:max-w-full max-md:px-0">
            <AddressButton />
          </div>

          {/* </>
          :
          <></>
        } */}
        </div >

      </>
    )
  }

  const PaymentMethodList = () => {

    const controller = new AbortController();

    const [data, setData] = useState(null);

    useEffect(() => {

      addressData();


      return () => {

        controller.abort();
      };
    }, []);

    const addressData = async () => {

      const tokenValue = localStorage?.getItem('login api response token');
      const storedToken = JSON?.parse(tokenValue);
      if (!storedToken) {
        navigate('/');
        // return;
      }

      const user_id = localStorage?.getItem('user_data');
      const userId = JSON.parse(user_id);

      const abortController = new AbortController();

      try {

        const formdata = new FormData();
        formdata.append("user_id", userId?.user_id);

        const result = await fetchWithFormDataToken(`api/v1/list_user_address`, formdata, abortController, null);
        if (result?.status === true) {
          setData(result?.data)
        }
        else {

        }
      } catch (error) {

      }
      finally {

        abortController.abort();
      }
    };


    const AddressCard = ({ addressAddData, default_address, flatno_building, address_type, onEdit, onDelete, onSetDefault, street_area, landmark, city, state, country, pincode }) => {

      const address = [
        flatno_building || '',
        street_area || '',
        landmark || '',
        city || '',
        state || '',
        country || '',
        pincode || ''
      ].filter(part => part.trim() !== '').join(', ');

      const Home = () => {
        return (
          <div className='flex items-center gap-2'>
            <ImgComp
              src={Images.icn_home_black}
              className='w-5'
            />
            <div>Home</div>
          </div>
        )
      }

      const Office = () => {
        return (
          <div className='flex items-center gap-2'>
            <ImgComp
              src={Images.icn_office_black}
              className='w-5'
            />
            <div>Office</div>
          </div>
        )
      }

      const Others = () => {
        return (
          <div className='flex items-center gap-2'>
            <ImgComp
              src={Images.icn_location_black}
              className='w-5'
            />
            <div>Others</div>
          </div>
        )
      }

      const renderContent = (title) => {
        switch (title) {
          case '1':
            return <Home />;
          case '2':
            return <Office />;
          case '3':
            return <Others />;
          default:
            return null;
        }
      };

      return (
        <section className="flex flex-row p-4 mt-4 text-xs leading-5 bg-white rounded-lg border border-gray-200 border-solid w-full items-center gap-3 ">
          <input type="radio"
            value={addressAddData?.id}
            checked={addressId === addressAddData?.id}
            onChange={() => setAddressId(addressAddData?.id)}
            className=' focus:border-0 focus:outline-0 focus:ring-0'
          />
          <div className='flex flex-row'>
            <div>
              <div className="flex gap-2 self-start text-base font-medium whitespace-nowrap text-neutral-900">
                <div>{renderContent(address_type)}</div>
              </div>
              <div className="mt-2 text-neutral-400 text-start">{address}</div>
            </div>
            {default_address === "0" &&
              <div className='text-amber-500 whitespace-nowrap text-xs font-bold'>
                <i className="fa fa-check border border-amber-500 rounded-full px-0.5 py-0.5 me-2"></i>
                Set as Default
              </div>
            }
          </div>
        </section>
      );
    }

    const handleChange = (event) => {
      setSelectedValue(event.target.value);
    };

    return (
      <>

        <div className='mybzb_bg_pinkclr rounded-lg p-1 text-start ps-3 text-sm'>
          <span className='mybzb_text_clr '>Note:</span> Select preferable store in case of pickup from store
        </div>

        {/* {data?.length > 0 ?
          <>
            {data?.map((addressData, index) => (
              <>
                <AddressCard key={index}
                  {...addressData}
                  addressAddData={addressData}
                />
              </>
            ))} */}
        <div
          className="flex flex-row p-4 mt-4 text-base leading-5 bg-white rounded-lg border border-gray-200 border-solid w-full items-center gap-3 "
        >

          <input
            // type="radio"
            // checked="0"
            // value="0"
            type="radio" id="0" name="paymentMethod"
            value="0"
            checked={selectedValue}
            // onChange={handleChange}
            onChange={(e) => setSelectedValue(e.target.value)}
            className=' focus:border-0 focus:outline-0 focus:ring-0'
          />
          Online
        </div>
        <footer className="flex flex-col justify-center items-end mt-8 text-base font-semibold leading-6 max-md:pl-5 max-md:max-w-full max-md:px-0">
          <div className="flex gap-4">
            <Link
              to={`/profile`}
              state={{ openAddressTab: true }}
            >
              <button
                type="button"
                className="focus:border-red-700 focus:outline-0 focus:ring-0 justify-center px-12 py-3.5 text-red-700 whitespace-nowrap rounded-lg border border-red-700 border-solid max-md:px-5"
                // onClick={() => onCancelBtn()}
                onClick={() => setIsAddressOpen(false) && setIsPaymentMethod(false)}

              >
                Cancel
              </button>
            </Link>

            <button
              type="submit"
              className="focus:border-red-700 focus:outline-0 focus:ring-0  justify-center px-8 py-3.5 text-white bg-red-700 rounded-lg max-md:px-5"
              // onClick={() => handleSubmit()}
              // onClick={() => handlePayment()}
              onClick={() => checkPayMethod()}
            >
              Place Order
            </button>
          </div>
        </footer>

        {/* :
           <></>
         }
       </> */}
      </>
    )
  }

  const addressRounting = () => {

    const getProfile = localStorage?.getItem('get user profile');
    const getProfileData = JSON.parse(getProfile);

    // console.log("getProfileData", getProfileData);
    if (getProfileData?.firstname === "") {
      navigate('/profile', { state: { openEditProfileTab: true } });
    }
    else {
      if (addressData?.length > 0) {
        setIsAddressOpen(true)

      } else {
        navigate('/profile', { state: { openAddressTab: true } });
      }
    }
  }


  return (
    <>
      <TwoBreadcrumb
        href3={`/`}
        name3={`Cart`}
      />

      {/* <main className="flex gap-5 pt-8 pb-20  max-md:flex-wrap max-md:pl-5"> */}
      <div className="flex flex-col py-20 max-md:py-10">
        <section
          className={`flex flex-col items-center self-center w-full max-w-${width} max-md:max-w-full  max-lg:px-5`}
        // className={`flex gap-5 max-md:max-w-full w-${width} max-w-full border border-green-500  mx-auto`}
        >

          {isEmptyObject(data) ?
            <>

              <ViewShoppingCart />
            </>

            :
            <>
              {data?.cart_items?.length > 0 ?
                <div className="flex gap-5 max-lg:flex-col max-w-full w-full">
                  <article className="flex flex-col max-md:ml-0 max-md:w-full max-w-full w-full">
                    <div className="flex flex-col grow items-start max-md:mt-5 max-md:max-w-full max-w-full">
                      <div className="flex flex-col self-stretch px-6 py-5 rounded-2xl border border-solid border-black border-opacity-10 max-md:px-5 max-md:max-w-full">

                        {data?.cart_items?.length > 0 ?
                          (
                            data?.cart_items.map((product, index) => (
                              <ProductItem key={index} product={product} fetchData={fetchData} />
                            ))
                          ) : (
                            <>
                              <div>Cart Is empty</div>
                            </>
                          )}
                      </div>

                      <div className="flex flex-col items-start self-stretch rounded-2xl mt-5">
                        <section className="self-stretch w-full max-md:max-w-full">
                          <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                            <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
                              <div className="flex flex-col grow leading-[150%] text-neutral-400 max-md:mt-4 max-md:max-w-full">
                                {!showNote &&
                                  <button className="focus:border-gray-200 focus:outline-0 focus:ring-0 flex gap-2 justify-between px-4 py-3.5 text-sm rounded-lg border border-gray-200 border-solid font-[510] text-neutral-400 max-md:flex-wrap max-md:max-w-full">
                                    <span className="my-auto">Add Instruction</span>
                                    <div onClick={() => setShowNote(!showNote)}>
                                      <i className='fa fa-plus'></i>
                                    </div>
                                  </button>
                                }
                                {showNote &&
                                  <div className="flex flex-col justify-center px-3 py-5 rounded-lg border border-gray-200 border-solid max-md:max-w-full">
                                    <div className="flex gap-5 justify-between text-sm font-[510] max-md:pr-5">
                                      <span>Add Instruction</span>
                                      <div className="cursor-pointer" onClick={() => setShowNote(false)}>
                                        <i className='fa fa-minus'></i>
                                      </div>
                                    </div>

                                    <input
                                      placeholder=' Add instruction'
                                      className="focus:border-gray-200 focus:outline-0 focus:ring-0  justify-center p-4 mt-2 text-xs bg-white rounded-lg border border-gray-200 border-solid"
                                      value={note}
                                      onChange={(e) => setNote(e.target.value)}
                                    />

                                  </div>
                                }
                              </div>
                            </div>
                            {/* <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
                              <div className="flex flex-col grow self-stretch leading-[150%] max-md:mt-4 max-md:max-w-full">
                                {isEmptyObject(defaultAddress) ?
                                  (
                                    <div className="flex gap-2 justify-between px-4 py-3.5 text-sm rounded-lg border border-gray-200 border-solid font-[510] text-neutral-400 max-md:flex-wrap max-md:max-w-full">
                                      <span className="my-auto">Delivery Address</span>
                                      <Link
                                        //  to={{
                                        //   pathname: '/profile',
                                        //   state: { openTab: 'My Addresses' }

                                        // }}
                                        to={`/profile`}
                                        state={{ openAddressTab: true }}
                                      // to={`/myaddress`}
                                      >
                                        <div >
                                          <i className='fa fa-plus'></i>
                                        </div>
                                      </Link>

                                    </div>
                                  )
                                  :
                                  (
                                    <div className="flex flex-col p-4 bg-white rounded-lg border border-gray-200 border-solid max-md:max-w-full">
                                      <div className="flex gap-2 justify-between w-full whitespace-nowrap">
                                        <div className="flex gap-2 text-base font-medium text-neutral-900">

                                          <ImgComp
                                            src={Images.icn_home_black}
                                            className="shrink-0 w-6 aspect-square"
                                          />
                                          <span>Home</span>
                                        </div>
                                        <div className="flex gap-1 my-auto text-xs text-neutral-400">

                                          <ImgComp
                                            src={Images.icn_edit}
                                            className="shrink-0 self-start w-4 aspect-square"
                                          />
                                          <span>Change</span>
                                        </div>
                                      </div>
                                      <div className="mt-2 text-xs text-neutral-400">
                                        848 Freed Drive, Stockton, California, 95202
                                      </div>
                                    </div>
                                  )
                                }
                               
                              </div>
                            </div> */}
                          </div>
                        </section>
                        {/* <section className="mt-4 text-xl font-medium text-neutral-900">
                          Payment Method
                        </section>
                        <section className="flex gap-4 mt-4 text-base leading-6 text-black">
                          <div className={`flex flex-1 gap-2 text-sm leading-5 whitespace-nowrap `}
                          //onChange={(event) => handleClick(event, id)}
                          >
                            <input
                              type="radio" id="0" name="paymentMethod"
                              value={0}
                              onChange={handleChange}
                            // checked="0"
                            />
                            <label
                              htmlFor='cod'
                            // className={`flex flex-1 gap-2 text-sm leading-5 whitespace-nowrap ${selectedValue === id ? 'text-black' : 'text-neutral-400'}`}
                            >
                              Pickup from store
                            </label>
                          </div>
                          <div className={`flex flex-1 gap-2 text-sm leading-5 whitespace-nowrap `}
                          //onChange={(event) => handleClick(event, id)}
                          >
                            <input
                              type="radio" id="1" name="paymentMethod"
                              value={1}
                              onChange={handleChange}
                            // checked="1"
                            />
                            <label
                              htmlFor='online'
                            // className={`flex flex-1 gap-2 text-sm leading-5 whitespace-nowrap ${selectedValue === id ? 'text-black' : 'text-neutral-400'}`}
                            >
                              Online
                            </label>
                          </div>

                        </section> */}
                      </div>

                    </div>
                  </article>
                  <aside className="flex flex-col max-md:ml-0 max-md:w-full 2xl:w-[40%] xl:w-[40%] lg:w-[80%]">
                    {data?.order_summary && (
                      <section className="flex flex-col px-6 pt-5 pb-8 text-base max-sm:text-sm rounded-2xl border border-solid border-black border-opacity-10 max-md:px-5 max-md:mt-5">
                        <header className="text-2xl font-bold text-neutral-900">Order Summary</header>
                        <div className="flex gap-5 justify-between mt-6">
                          <p className="leading-[150%] text-neutral-400">Sub Total </p>
                          <p className="font-semibold text-neutral-900">
                            ₹ {Number(data?.order_summary?.Sub_total).toLocaleString('en-IN')}

                          </p>
                        </div>
                        <div className="flex gap-5 justify-between mt-3">
                          <p className="leading-[150%] text-neutral-400">Discount</p>
                          <p className="font-semibold text-red-700">
                            - ₹ {Number(data?.order_summary?.discount).toLocaleString('en-IN')}
                          </p>
                        </div>
                        <div className="flex gap-5 justify-between mt-3">
                          <p className="leading-[150%] text-neutral-400">Coupon Discount</p>
                          <p className="font-semibold text-red-700">
                            - ₹ {Number(data?.order_summary?.coupon_discount).toLocaleString('en-IN')}
                          </p>
                        </div>

                        <div className="flex gap-5 justify-between mt-3 text-neutral-900">
                          <p className="leading-[150%]">Sub total After Discount</p>
                          <p className="font-semibold"
                          >
                            ₹ {Number(data?.order_summary?.Sub_total_after_discount).toLocaleString('en-IN')}
                          </p>
                        </div>
                        <div className="flex gap-5 justify-between mt-3 text-neutral-900">
                          <p className="leading-[150%]">Tax</p>
                          <p className="font-semibold">
                            ₹ {Number(data?.order_summary?.tax).toLocaleString('en-IN')}
                          </p>
                        </div>

                        <div className="flex gap-5 justify-between mt-3">
                          <p className="font-semibold leading-[150%] text-neutral-900">Grand total</p>
                          <p className="font-bold text-red-700">
                            ₹ {Number(data?.order_summary?.grand_total).toLocaleString('en-IN')}
                          </p>
                        </div>
                        <button className="focus:border-red-700 focus:outline-0 focus:ring-0 flex gap-3 justify-center px-8 py-4 mt-6 font-bold tracking-normal text-white bg-red-700 rounded max-md:px-5"
                          // onClick={() => placeOrder()}
                          // onClick={() => handlePayment()}
                          // onClick={() => checkPayMethod()}
                          // onClick={() => setIsAddressOpen(true)}
                          onClick={addressRounting}
                        >
                          Proceed to Pay
                          <i className='fa fa-arrow-right text-white text-lg'></i>
                        </button>
                      </section>
                    )}

                  </aside>
                </div>
                :
                <>
                  <ViewShoppingCart />
                </>
              }
            </>
          }


        </section>

        {/* <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/b786201c376d6aa16cd0cfba94ef1722cb6ced6a7d339ac69f6ad0200d81853d?apiKey=8e5983036f284a13866aae97819cdb73&" alt="" className="shrink-0 self-start mt-8 max-w-full aspect-[0.34] w-[183px]" /> */}
        {/* </main> */}
      </div>

      {isModalOpen && (
        <PaymentSuccess
          onClose={() => setIsModalOpen(false)}
          modalWidth={`max-w-xl`}
          orderId={successData?.order_id || ''}

        />
      )}

      {isModalFailOpen && (
        <PaymentFail
          onClose={() => setIsModalFailOpen(false)}
          modalWidth={`max-w-xl`}
          buttonContent={failedButton}
        />
      )}

      {isModalCodOpen &&
        <Thankyou
          modalWidth={`max-w-xl`}
          onClose={() => setIsModalCodOpen(false)}
        />
      }

      {isAddressOpen &&
        <AddressModalBox
          title={`Add Address`}
          modalContent={<AddressModalList addressId={addressId} />}
          modalWidth={`max-w-xl`}
          onClose={() => {
            setIsAddressOpen(false);
            setAddressId(null);
          }
          }
        />
      }

      {isPaymentMethod &&
        <AddressModalBox
          title={`Payment Method`}
          modalContent={<PaymentMethodList />}
          modalWidth={`max-w-xl`}
          onClose={() => {
            setIsPaymentMethod(false)
            setSelectedValue(null)
          }
          }
        />
      }


    </>
  )
}

export default ShoppingCartContent